import React, { Component, useState, useEffect } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import { Button, Form, Modal } from "react-bootstrap";
import classes from "./Table.module.css";
import paginationFactory from "react-bootstrap-table2-paginator";

import arrowLeft from "./../img/arrow-left.svg";
import arrowLeftDouble from "./../img/arrow-left-double.svg";
import arrowRight from "./../img/arrow-right.svg";
import arrowRightDouble from "./../img/arrow-right-double.svg";

const customCarret = (order, column) => {
  if (!order)
    return (
      <>
        <span className="-carret" />
        <span className="-carret -open" />
      </>
    );
  else if (order === "asc") return <span className="-carret" />;
  else if (order === "desc") return <span className="-carret -open" />;
  return null;
};

let columns = [
  {
    dataField: "chassis",
    sort: true,
    text: "Chassis",
    sortCaret: (order, column) => {
      return customCarret(order, column);
    },
  },
  {
    dataField: "brand",
    text: "Merk",
    sort: true,
    sortCaret: (order, column) => {
      return customCarret(order, column);
    },
  },
  {
    dataField: "type",
    text: "Type",
    sort: true,
    sortCaret: (order, column) => {
      return customCarret(order, column);
    },
  },
  {
    dataField: "model",
    text: "Model",
    sort: true,
    sortCaret: (order, column) => {
      return customCarret(order, column);
    },
  },
  {
    dataField: "color",
    text: "Kleur",
    sort: true,
    sortCaret: (order, column) => {
      return customCarret(order, column);
    },
  },
  {
    dataField: "placeName",
    text: "Plaats",
    sort: true,
    sortCaret: (order, column) => {
      return customCarret(order, column);
    },
  },
  // {
  //   dataField: "lastUpdate",
  //   text: "Update",
  //   sort: true,
  //   hidden: true,
  //   headerSortingStyle,
  // },
  {
    dataField: "registration",
    text: "Registratie",
    sort: true,
    sortCaret: (order, column) => {
      return customCarret(order, column);
    },
  },
  {
    dataField: "pinStatus",
    text: "Status",
    sort: true,
    sortCaret: (order, column) => {
      return customCarret(order, column);
    },
  },
  {
    dataField: "scannedTime",
    text: "Gescand op",
    hidden: true,
    sortCaret: (order, column) => {
      return customCarret(order, column);
    },
  },
];



const MySearch = (props) => {
  const handleChange = (input) => {
    console.log(input.target.value);
    props.onSearch(input.target.value);
  };
  return (
    <input
      type="text"
      className="search"
      placeholder="Zoeken"
      onChange={handleChange}
    />
  );
};

const rowStyle2 = (row, rowIndex) => {
  const style = {};
  if (row.pinStatus === "Sold" || row.pinStatus === "Delete") {
    style.backgroundColor = "#d9534f";
  }

  return style;
};

export default function NewTable(props) {
  const [dropdownClass, setDrowndownClass] = useState("dropdown");
  const [currentPage, setCurrentPage] = useState(1);


  useEffect(() => {
    console.log("colnums")
    console.log(props.tableColumns)
  },[]);

  const rowEvents = {
    onClick: (e, row, rowIndex) => {
      console.log(rowIndex);
      props.onClickRow(row, rowIndex);
    },
  };
  console.log("propsshowscantime " + props.showScanTime);
  columns[columns.length - 1].hidden = true;
  if (props.showScanTime) {
    columns[columns.length - 1].hidden = false;
  }
  // columns[columns.length - 1].events = {
  //   onClick: (e, column, columnIndex, row, rowIndex) => {
  //     this.getEventsPin(row);
  //     this.setState({ show: true });
  //   },
  // };

  const onClickPageSelectorDropdown = () => {
    if (dropdownClass === "dropdown") setDrowndownClass("dropdown -open");
    else setDrowndownClass("dropdown");
  };

  const onClickPageSelectorSize = (e, onSizePerPageChange) => {
    console.log(e.target.value);
    setDrowndownClass("dropdown");
    onSizePerPageChange(e.target.value);
  };
  const sizePerPageRenderer = ({
    options,
    currSizePerPage,
    onSizePerPageChange,
  }) => (
    <div
      className={dropdownClass}
      role="group"
      onClick={onClickPageSelectorDropdown}
      onChange={(e) => onClickPageSelectorSize(e, onSizePerPageChange)}
    >
      <div className="dropdown__list">
        {options.map((option) => {
          const isSelect = currSizePerPage === `${option.page}`;
          return (
            <div className="dropdown__option">
              <input
                name="max"
                type="radio"
                value={option.text}
                id={option.text}
                checked={isSelect}
              />
              <label for={option.text}>{option.text}</label>
            </div>
          );
        })}
      </div>
    </div>
  );

  const pageListRenderer = ({ pages, onPageChange }) => {
    const pageWithoutIndication = pages.filter(
      (p) => typeof p.page !== "string"
    );

    return (
      <div className="pagination">
        <span className="pagination__item">
          <img src={arrowLeftDouble} alt="" onClick={() => onPageChange(1)} />
        </span>
        <span
          className="pagination__item"
          onClick={() => onPageChange(currentPage === 1 ? 1 : currentPage - 1)}
        >
          <img src={arrowLeft} alt="" />
        </span>
        {pageWithoutIndication.map((p) => (
          <span
            className="pagination__item"
            onClick={() => onPageChange(p.page)}
          >
            {p.page}
          </span>
        ))}
        <span
          className="pagination__item"
          onClick={() =>
            onPageChange(
              currentPage === pages.length ? pages.length : currentPage + 1
            )
          }
        >
          <img src={arrowRight} alt="" />
        </span>
        <span className="pagination__item">
          <img
            src={arrowRightDouble}
            alt=""
            onClick={() => onPageChange(pages.length)}
          />
        </span>
      </div>
    );
  };

  const MySearch = (props) => {
    const handleClick = () => {
      props.onExport();
    };
    const handleChange = (input) => {
      console.log(input.target.value);
      props.onSearch(input.target.value);
    };
    return (
      <div className="table__filter">
        <input type="text" className="searchBar" placeholder="Zoeken..." onChange={handleChange} />
        {props.showCsv && <a className="button" onClick={handleClick}>
          Exporteren
        </a>}
      </div>
    );
  };

  const mystyleright = {
    float: "right"
  };

  const mystyleleft = {
    float: "left"
  };


  return (
    <>
      <ToolkitProvider
        keyField="id"
        data={props.pinList}
        columns={props.tableColumns?props.tableColumns:columns}
        search
        exportCSV={{ onlyExportFiltered: true, exportAll: false}}
      >
        {(tableProps) => (
          <div className={classes.Table}>
            {props.csv?<MySearch {...tableProps.searchProps} {...tableProps.csvProps} showCsv={props.csv} />:<MySearch {...tableProps.searchProps} />}
            {props.importButton ? (
              <div class="part-12" style={mystyleleft}>
                <label for="file" class="file">

                  <span class="button">Importeer XLSX</span>
                  <input
                    type="file"
                    id="file"
                    onChange={(e) => props.loadFile(e)}
                  />
                  <div hidden={!props.showImportButtonError}class={"form__error"}>{props.importButtonErrorText}</div>
                </label>
              </div>
            ) : (
              <></>
            )}
                        {props.addButton ? (

              <div class="part-12" style={mystyleright}>
                                <label class="file">

                  <span
              class="button"
              onClick={props.addManual}
            >+ Manueel toevoegen</span>
            </label>
              </div>
            ) : (
              <></>
            )}
            <BootstrapTable
              classes="_table -clickable table-borderless"
              rowClasses="-open-model"
              bootstrap4={true}
              keyField="lastUpdate"
              className={classes.Table}
              hover
              rowEvents={rowEvents}
              rowStyle={rowStyle2}
              {...tableProps.baseProps}
              pagination={paginationFactory({
                sizePerPageRenderer,
                pageListRenderer,
                onPageChange: (page, sizePerPage) => {
                  setCurrentPage(page);
                },
              })}
            />
          </div>
        )}
      </ToolkitProvider>
    </>
  );
}
