import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import * as routes from "./../routes";
import logo from "./../img/logo.svg";
import logout from "./../img/icon-logout.svg";
import { useAppContext } from "./../libs/contextLib";

// Be sure to include styles at some point, probably during your bootstraping

function SideBar(props) {
  const [activeKey, setActiveKey] = useState(props.history.location.pathname);

  const {
    company,
    username,
    hasParking
  } = useAppContext();

  const onSelect = (selected) => {
    console.log("history");
    console.log(props.history);
    props.history.push(selected);
    if(selected === activeKey){
        props.history.replace('/reload');
        setTimeout(() => {
            props.history.replace(selected)
            }, 0)
    }
    setActiveKey(selected);
  };
  return (
    <nav className="nav">
    <div className="nav__top">
        <a className="nav__logo" onClick={() => {onSelect(routes.HOME)}}>
            <img src={logo} alt="Logo CoDek" />
        </a>
        <div className="menu">
        <a className={`menu__item ${activeKey===routes.HOME?"-active":""}`} onClick={() => {onSelect(routes.HOME)}}>
                        <div className="menu__icon">
                            <svg viewBox="0 0 13.03 13.24">
                                <rect width="3.26" height="3.26" rx=".97" />
                                <rect x="4.89" width="3.26" height="3.26" rx=".97" />
                                <rect x="9.77" width="3.26" height="3.26" rx=".97" />
                                <rect x="4.89" y="9.98" width="3.26" height="3.26" rx=".97" />
                                <rect x="9.77" y="9.98" width="3.26" height="3.26" rx=".97" />
                            </svg>
                        </div>
                        Scannen
                    </a>
                    <a className={`menu__item ${activeKey===routes.CABINET?"-active":""}`} onClick={() => {onSelect(routes.CABINET)}}>
                        <div className="menu__icon">
                            <svg viewBox="0 0 13.03 13.24">
                                <rect width="3.26" height="3.26" rx=".97" />
                                <rect x="4.89" width="3.26" height="3.26" rx=".97" />
                                <rect x="9.77" width="3.26" height="3.26" rx=".97" />
                                <rect y="4.99" width="3.26" height="3.26" rx=".97" />
                                <rect x="4.89" y="4.99" width="3.26" height="3.26" rx=".97" />
                                <rect x="9.77" y="4.99" width="3.26" height="3.26" rx=".97" />
                                <rect y="9.98" width="3.26" height="3.26" rx=".97" />
                                <rect x="4.89" y="9.98" width="3.26" height="3.26" rx=".97" />
                                <rect x="9.77" y="9.98" width="3.26" height="3.26" rx=".97" />
                            </svg>
                        </div>
                        Kasten
                    </a>
                    {hasParking && <a className={`menu__item ${activeKey===routes.PARKING?"-active":""}`} onClick={() => {onSelect(routes.PARKING)}}>
                        <div className="menu__icon">
                            <svg viewBox="0 0 13.03 13.24">
                                <rect width="3.26" height="3.26" rx=".97" />
                                <rect x="4.89" width="3.26" height="3.26" rx=".97" />
                                <rect x="9.77" width="3.26" height="3.26" rx=".97" />
                                <rect y="4.99" width="3.26" height="3.26" rx=".97" />
                                <rect x="4.89" y="4.99" width="3.26" height="3.26" rx=".97" />
                                <rect x="9.77" y="4.99" width="3.26" height="3.26" rx=".97" />
                                <rect y="9.98" width="3.26" height="3.26" rx=".97" />
                                <rect x="4.89" y="9.98" width="3.26" height="3.26" rx=".97" />
                                <rect x="9.77" y="9.98" width="3.26" height="3.26" rx=".97" />
                            </svg>
                        </div>
                        Parking
                    </a>}
                    <a className={`menu__item ${activeKey===routes.TABLE?"-active":""}`} onClick={() => {onSelect(routes.TABLE)}}>
                        <div className="menu__icon">
                            <svg viewBox="0 0 13.03 9.65">
                                <rect width="1.95" height="1.95" rx=".97" />
                                <rect x="2.58" width="10.45" height="1.95" rx=".97" />
                                <rect y="3.8" width="1.95" height="1.95" rx=".97" />
                                <rect x="2.58" y="3.8" width="10.45" height="1.95" rx=".97" />
                                <rect y="7.71" width="1.95" height="1.95" rx=".97" />
                                <rect x="2.58" y="7.71" width="10.45" height="1.95" rx=".97" />
                            </svg>
                        </div>
                        Lijsten
                    </a>
                    {/* <a className={`menu__item ${activeKey===routes.REPORTS?"-active":""}`} onClick={() => {onSelect(routes.REPORTS)}}>
                        <div className="menu__icon">
                            <svg viewBox="0 0 11 11">
                                <path transform="scale(0.03,0.03)" d="M224 136V0H24C10.7 0 0 10.7 0 24v464c0 13.3 10.7 24 24 24h336c13.3 0 24-10.7 24-24V160H248c-13.2 0-24-10.8-24-24zM64 72c0-4.42 3.58-8 8-8h80c4.42 0 8 3.58 8 8v16c0 4.42-3.58 8-8 8H72c-4.42 0-8-3.58-8-8V72zm0 64c0-4.42 3.58-8 8-8h80c4.42 0 8 3.58 8 8v16c0 4.42-3.58 8-8 8H72c-4.42 0-8-3.58-8-8v-16zm192.81 248H304c8.84 0 16 7.16 16 16s-7.16 16-16 16h-47.19c-16.45 0-31.27-9.14-38.64-23.86-2.95-5.92-8.09-6.52-10.17-6.52s-7.22.59-10.02 6.19l-7.67 15.34a15.986 15.986 0 0 1-14.31 8.84c-.38 0-.75-.02-1.14-.05-6.45-.45-12-4.75-14.03-10.89L144 354.59l-10.61 31.88c-5.89 17.66-22.38 29.53-41 29.53H80c-8.84 0-16-7.16-16-16s7.16-16 16-16h12.39c4.83 0 9.11-3.08 10.64-7.66l18.19-54.64c3.3-9.81 12.44-16.41 22.78-16.41s19.48 6.59 22.77 16.41l13.88 41.64c19.77-16.19 54.05-9.7 66 14.16 2.02 4.06 5.96 6.5 10.16 6.5zM377 105L279.1 7c-4.5-4.5-10.6-7-17-7H256v128h128v-6.1c0-6.3-2.5-12.4-7-16.9z" fill-rule="evenodd"/>
                            </svg>
                        </div>
                        Rapporten
                    </a> */}
                    <a className={`menu__item ${activeKey===routes.NFC?"-active":""}`} onClick={() => {onSelect(routes.NFC)}}>
                        <div className="menu__icon">
                            <svg viewBox="0 0 11 11">
                                <path transform="translate(-.33)" d="M10.58,4.75h-4v-4a.75.75,0,0,0-1.5,0v4h-4a.75.75,0,0,0,0,1.5h4v4a.75.75,0,0,0,1.5,0v-4h4a.75.75,0,0,0,0-1.5Z" fill-rule="evenodd"/>
                            </svg>
                        </div>
                        Toevoegen
                    </a>
                    {props.isAdmin ? ( <><hr/>
                    <a className={`menu__item ${activeKey===routes.USERS?"-active":""}`} onClick={() => {onSelect(routes.USERS)}}>
                        <div className="menu__icon">
                            <svg viewBox="0 0 9.75 10.5">
                                <path transform="translate(-.5)" d="M5.5,5A2.5,2.5,0,1,1,8,2.5,2.5,2.5,0,0,1,5.5,5Zm0-4A1.5,1.5,0,1,0,7,2.5,1.5,1.5,0,0,0,5.5,1Z" />
                                <path transform="translate(-.5)" d="M9.75,10.5a.5.5,0,0,1-.5-.49c0-2.4-1.28-3.51-4-3.51a4.09,4.09,0,0,0-3,1A3.39,3.39,0,0,0,1.5,10,.5.5,0,0,1,1,10.5.51.51,0,0,1,.5,10,4.34,4.34,0,0,1,1.55,6.75,5,5,0,0,1,5.29,5.5c4.06,0,4.94,2.44,5,4.5a.5.5,0,0,1-.5.5Z" />
                            </svg>
                        </div>
                        Gebruikers
                    </a>
                    <a className={`menu__item ${activeKey===routes.BADGES?"-active":""}`} onClick={() => {onSelect(routes.BADGES)}}>
                        <div className="menu__icon">
                            <svg viewBox="0 0 14 11">
                                <path transform="translate(1336.5 -8.24)" d="m-1323.5 10.24h-4.48v-1a1 1 0 0 0-1-1h-1a1 1 0 0 0-1 1v1h-4.49a1 1 0 0 0-1 1v7a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-7a1 1 0 0 0-1.03-1zm-6.48-1h1v2h-1v-2zm6.5 9h-12v-7h4.5a1 1 0 0 0 1 1h1a1 1 0 0 0 1-1h4.51z" />
                                <path transform="translate(1336.5 -8.24)" d="M-1332.5,13.74a.5.5,0,0,0,.5.5h5a.5.5,0,0,0,.5-.5.51.51,0,0,0-.5-.5h-5A.5.5,0,0,0-1332.5,13.74Z" />
                                <path transform="translate(1336.5 -8.24)" d="M-1327,15.24h-5a.5.5,0,0,0-.5.5.5.5,0,0,0,.5.5h5a.5.5,0,0,0,.5-.5A.51.51,0,0,0-1327,15.24Z" />
                            </svg>

                        </div>
                        Badges
                    </a>
                    <a className={`menu__item ${activeKey===routes.SETTINGS?"-active":""}`} onClick={() => {onSelect(routes.SETTINGS)}}>                        <div className="menu__icon">
                            <svg viewBox="0 0 13.49 14.08">
                                <path transform="translate(1845.8 -496.28)" d="M-1838.32,510.36h-1.53a1.7,1.7,0,0,1-1.7-1.7v-.21a5.65,5.65,0,0,1-.8-.48l-.22.12a1.7,1.7,0,0,1-1.29.15,1.72,1.72,0,0,1-1-.81l-.74-1.34a1.68,1.68,0,0,1-.14-1.3,1.64,1.64,0,0,1,.81-1l.18-.11c0-.11,0-.23,0-.35s0-.2,0-.3l-.18-.1a1.71,1.71,0,0,1-.67-2.31l.74-1.34a1.69,1.69,0,0,1,1-.81,1.75,1.75,0,0,1,1.29.14l.17.1a5.11,5.11,0,0,1,.85-.52V498a1.7,1.7,0,0,1,1.7-1.7h1.53a1.7,1.7,0,0,1,1.7,1.7v.2a5.47,5.47,0,0,1,.84.52l.17-.1a1.78,1.78,0,0,1,1.3-.14,1.7,1.7,0,0,1,1,.81l.74,1.34a1.69,1.69,0,0,1-.66,2.31l-.18.1c0,.1,0,.2,0,.3a2,2,0,0,1,0,.35l.19.11a1.67,1.67,0,0,1,.81,1,1.69,1.69,0,0,1-.15,1.3l-.74,1.34a1.73,1.73,0,0,1-1,.81,1.73,1.73,0,0,1-1.3-.15l-.22-.12a5.05,5.05,0,0,1-.79.48v.21A1.7,1.7,0,0,1-1838.32,510.36Zm-3.94-3.58.26.2a4.82,4.82,0,0,0,1.14.69l.31.13v.86a.7.7,0,0,0,.7.7h1.53a.71.71,0,0,0,.7-.7v-.86l.31-.13a4.58,4.58,0,0,0,1.13-.69l.26-.2.8.44a.7.7,0,0,0,.53.06.71.71,0,0,0,.42-.34l.74-1.34a.7.7,0,0,0,.06-.53.7.7,0,0,0-.33-.42h0l-.78-.43.06-.5a2.71,2.71,0,0,0,0-.4,1.86,1.86,0,0,0,0-.34l0-.51.77-.42a.71.71,0,0,0,.27-1l-.74-1.34a.7.7,0,0,0-.42-.33.7.7,0,0,0-.53.06l-.76.42-.26-.22a4.76,4.76,0,0,0-1.17-.71l-.31-.13V498a.7.7,0,0,0-.7-.7h-1.53a.7.7,0,0,0-.7.7v.86l-.31.13a4.63,4.63,0,0,0-1.18.71l-.26.22-.75-.42a.7.7,0,0,0-.53-.06.7.7,0,0,0-.42.33l-.74,1.34a.71.71,0,0,0,.27,1l.77.42-.06.51c0,.11,0,.22,0,.34a2.85,2.85,0,0,0,0,.4l.06.5-.78.43a.7.7,0,0,0-.33.42.7.7,0,0,0,.06.53l.74,1.34a.71.71,0,0,0,.42.34.7.7,0,0,0,.53-.06Z" />
                                <path transform="translate(1845.8 -496.28)" d="m-1839.1 505.81a2.5 2.5 0 0 1-2.49-2.49 2.49 2.49 0 0 1 2.49-2.49 2.48 2.48 0 0 1 2.49 2.49 2.49 2.49 0 0 1-2.49 2.49zm0-4a1.49 1.49 0 0 0-1.49 1.49 1.49 1.49 0 0 0 1.49 1.49 1.49 1.49 0 0 0 1.49-1.49 1.49 1.49 0 0 0-1.49-1.47z" />
                            </svg>

                        </div>
                        Instellingen
                    </a></>) :(
        <></>
      )}
          </div>
          <div class="nav__logout">
            <div class="username__text">                {username}
</div>
        <a  class="nav__logout" onClick={ () => {props.handleLogout()}}>
                <img src={logout} alt=""/>
                Uitloggen <br/>

            </a>
            </div>
        </div>

    </nav>
  );
}

export default withRouter(SideBar);
