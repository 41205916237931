import React, { memo, useEffect, useState } from "react";
import ParkingRow from "./ParkingRow";
import ParkingCard from "./ParkingCard";
function ParkingGrid(props) {
  const [showCard, setShowCard] = useState(false);
  const [clickedCar, setClickedCar] = useState([]);
  const [pos, setPos] = useState("");

  useEffect(() => {

  }, []);

  const handleClickPlace = async (pins,pos) => {
    console.log("Handle Click Place");
    console.log(pins);
    setClickedCar(pins);
    setPos(pos);
    setShowCard(true);
  };

  const generateRows = (amount, columns,carList,formatter) => {
    let pinRow = [];
    const rows = Math.ceil(amount/ columns)

    for (let index = 0; index < rows; index++) {
      pinRow.push(
        <ParkingRow
          col={columns}
          row={index}
          amount={amount}
          carList={carList}
          handleClickPlace={handleClickPlace}
          formatter={formatter}
        />
      );
    }
    return pinRow;
  };

  const handleClose = () => {
    setShowCard(false);
  }

  return (
    <>
      <div className="board">
        <div class="parking__holder">
          {generateRows(props.amount, props.columns,props.carList, props.formatter)}
        </div>
        <div className={`model ${showCard? "-active" : ""}`}>
            <div class="model__container">

            <ParkingCard clickedCar={clickedCar} pos={pos} handleClose={handleClose}/>
            </div>
            </div>
      </div>
    </>
  );
}

export default ParkingGrid = memo(ParkingGrid);
