import React, { useState, useEffect } from "react";
import { useAppContext } from "./../libs/contextLib";
import crossIcon from "./../img/icon-cross.svg";

import { withRouter, useHistory } from "react-router-dom";
import * as routes from "./../routes";

import {
  changeAttributesAWS,
  changeChangePlaceSettingsAWS
}
 from "./../libs/lambdaLib";
let attributesAdded = false;

export default function CompanyPinList() {
  const history = useHistory();

  const [tempAttributes,setTempAttributes] = useState([]);
  const [tempUUID,setTempUUID] = useState("");
  const [showAddAttributeModal,setShowAddAttributeModal] = useState(false);
  const [isValidAttribute,setIsValidAttribute] = useState(false);
  const [newAttribute,setNewAttribute] = useState("");
  const [attributesArray,setAttributesArray] = useState([]);
  const {
    company,
    attributes,
    setAttributes,
    setUUID,
    UUID,
    changePlace,
        setChangePlace
  } = useAppContext();

  useEffect(() => {
    if(attributes.length > 0 ){
      setTempAttributes([...attributes]);
    }
  }, []);

  useEffect(() => {
    setTempUUID(UUID)
  },[UUID])


 const  onChangeCheck = (event,index) => {
   setTempAttributes((prev) => {
      const copy = [...prev];
      copy[index].default =  copy[index].default===1?0:1;
      return copy;
   })
  }

  const onChangeChangePlaceSetting = async (e,setting) => {
    setChangePlace((prev)=> {
      let copy = {...prev};
      copy[setting] = !copy[setting];
      changeChangePlaceSettingsAWS(company,copy);
      return copy
    })
  }



  const addAttribute = async () => {
    setTempAttributes((prev) => {
      let copy = [...prev];
      copy.push({
        attribute: newAttribute,
        default:0
      })
      changeAttributesAWS(company,copy)
      setTempAttributes(copy);
      return copy;
    })
    setNewAttribute("");
    setShowAddAttributeModal(false);
  }
  useEffect(() => {
    setAttributesArray(tempAttributes.map((element,index) => {
      return (<div class="form__control toggle">
       <input type="checkbox" id={element.attribute} defaultChecked={ element.default===0?false:true}onChange={(e) => {onChangeCheck(e,index)}}/>
       <label for={element.attribute}>
           {element.attribute}
           <div class="toggle__switch">
               <span class="toggle__check"></span>
           </div>
       </label>
   </div>)
      }));
  },[tempAttributes])


  useEffect(() => {
    if(newAttribute.length ==0){
      setIsValidAttribute(false);
      return;
    }
    let valid = true;
    for (let i = 0; i < tempAttributes.length; i++) {
      const element = tempAttributes[i];
      if(element.attribute === newAttribute || element.attribute === UUID){
        valid = false;
      }
    }
    setIsValidAttribute(valid);
  },[newAttribute])
  return (
    <>
          <div
        class={`model ${showAddAttributeModal ? "-active" : ""}`}
        data-model="add-user"
      >
        <div class="model__container">
          <h1>Attribuut toevoegen</h1>
          <div class={`form__control ${!isValidAttribute ? "-error" : ""}`}>
            <label for="email">Naam</label>
            <input type="text" onChange={(e) => setNewAttribute(e.target.value)} />
          </div>
          <p class="form__error" hidden={isValidAttribute}>
            {newAttribute.length > 0 ?"Attribuut bestaat al":"Leeg attribuut"}
          </p>
          <button
            class={`button -wide ${!isValidAttribute ? "-disabled" : ""}`}
            onClick={addAttribute}
            disabled={!isValidAttribute}
          >
            Toevoegen
          </button>
          <img
            src={crossIcon}
            alt=""
            class="model__cross"
            onClick={() => {
              setShowAddAttributeModal(false);
            }}
          />
        </div>
      </div>
      <section>

                    <div class="tab">
                        <div class="tab__menu">
                        <a data-tab="1" class="tab__item -active">Status</a>
                            <a data-tab="2" class="tab__item -active">Attributen</a>
                            <a data-tab="3" class="tab__item -active">Geavanceerd</a>
                        </div>
                        <div class="tab__plane">
                            <div data-tab="1" class="tab__panel" id="status">
                                <h6>Status</h6>
                                <div class="form__control toggle">
                                  <input type="checkbox" id="placeEdit" defaultChecked={changePlace["withoutScan"]}
                                  onChange={(e) => {onChangeChangePlaceSetting(e,"withoutScan")}
                                  }/>
                                  <label for="placeEdit">
                                      Wijzig plaats mogelijk zonder scan
                                      <div class="toggle__switch">
                                          <span class="toggle__check"></span>
                                          </div>
                                  </label>
                                  </div>
                                  <div class="form__control toggle">
                                  <input type="checkbox" id="placeCabinet" defaultChecked={changePlace["inBoard"]}
                                   onChange={(e) => {onChangeChangePlaceSetting(e,"inBoard")}
                                   }/>
                                  <label for="placeCabinet">
                                      Wijzig plaats mogelijk sleutel in kast
                                      <div class="toggle__switch">
                                          <span class="toggle__check"></span>
                                          </div>
                                  </label>
                                  </div>
                            </div>
                            <div data-tab="2" class="tab__panel" id="attributes">
                                <h6>Attributen</h6>
                                <div class="form__control">
                                    <label for="name">UUID</label>
                                    <input type="text" defaultValue={UUID} onChange={(e) => setTempUUID(e.target.value)}/>
                                </div>
                                {attributesArray
                                }
                                 <a
              class="button"
              onClick={() => {
                setAttributes(tempAttributes);
                setUUID(tempUUID);
                changeAttributesAWS(company,tempAttributes,tempUUID)
              }}
            >
              Wijziging opslaan
            </a>
            <a
              class="button -small"
              onClick={() => {
                setShowAddAttributeModal(true);
              }}
            >
              + Attribuut
            </a>
                                </div>
                                <div data-tab="3" class="tab__panel" id="status">
                                <h6>Geavanceerd</h6>

                                <a
              class="button -small"
              onClick={() => {history.push(routes.NEWPIN)}
                
              }
            >
              Nieuwe Pins toevoegen
            </a>
            </div>
                    </div>

                </div>
      </section>
    </>
  );
}
