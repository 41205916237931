import React, { memo } from "react";

function showButtons(props) {
  let buttons = [];

  if (!props.buttons || props.buttons.length === 0) return;
  //console.log(props.buttons);
  for (let index = 0; index < props.col; index++) {
    //console.log(props.buttons[index]);
    let buttonColor = "";
    if(props.buttons[index].pin && Object.keys(props.buttons[index].pin).length !== 0 ) buttonColor= "-green";
    else if(props.buttons[index].pinID === "u"){
       buttonColor= "-error";
    };
    buttons.push(
      <div className={`board__pin  ${buttonColor}`}>
          <span className={`board__number`} onClick={() => {props.handleClickPlace(props.buttons[index],props.row * 10 + index*1 + 1)}}>{props.row * 10 + index*1 + 1} </span>
      </div>
    );
  }
  return (   <div className="board__row">{buttons}</div>);
}

function ButtonRow(props) {
  return <>{showButtons(props)}</>;
}

export default ButtonRow = memo(ButtonRow);
