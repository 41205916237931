import React, { useState, useEffect } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCamera } from "@fortawesome/free-solid-svg-icons";
import { getPictureAWS } from "../libs/lambdaLib";
import { useAppContext } from "./../libs/contextLib";
import classes from "./EventsTable.module.css";

const headerSortingStyle = { backgroundColor: "#c8e6c9" };

const customCarret = (order, column) => {
  if (!order)
    return (
      <>
        <span className="-carret" />
        <span className="-carret -open" />
      </>
    );
  else if (order === "asc") return <span className="-carret" />;
  else if (order === "desc") return <span className="-carret -open" />;
  return null;
};

const columns = [
  // {
  //   dataField: "timeStamp",
  //   text: "TimeStamp",
  //   hidden: true,
  // },
  {
    dataField: "timestamp",
    text: "TimeStamp",
    hidden: true,
  },
  {
    dataField: "chassis",
    headerSortingStyle,
    hidden: true,
  },
  {
    dataField: "dag",
    text: "Day",
    sort: true,
    sortCaret: (order, column) => {
      return customCarret(order, column);
    },
    sortFunc: (a, b, order, dataField, rowA, rowB) => {
      if (order === "asc") {
        return new Date(rowB.timestamp) - new Date(rowA.timestamp);
      }
      return new Date(rowA.timestamp) - new Date(rowB.timestamp);
    },
  },
  {
    dataField: "time",
    text: "Tijd",
    sortCaret: (order, column) => {
      return customCarret(order, column);
    },
  },
  {
    dataField: "pinStatus",
    text: "Status",
    sort: true,
    sortCaret: (order, column) => {
      return customCarret(order, column);
    },
  },
  {
    dataField: "placeString",
    text: "Plaats",
    sort: true,
    sortCaret: (order, column) => {
      return customCarret(order, column);
    },
  },
  {
    dataField: "username",
    text: "Gebruiker",
    sort: true,
    sortCaret: (order, column) => {
      return customCarret(order, column);
    },
  },
];

const columnsPlaceEvent = [
  {
    dataField: "timestamp",
    text: "TimeStamp",
    headerSortingStyle,
    hidden: true,
  },
  {
    text: "Chassis",
    dataField: "chassis",
    sortCaret: (order, column) => {
      return customCarret(order, column);
    },
  },
  {
    dataField: "day",
    text: "Day",
    sort: true,
    headerSortingStyle,
    sortFunc: (a, b, order, dataField, rowA, rowB) => {
      console.log(rowA.timestamp);
      if (order === "asc") {
        return new Date(rowB.timestamp) - new Date(rowA.timestamp);
      }
      return new Date(rowA.timestamp) - new Date(rowB.timestamp);
    },
    sortCaret: (order, column) => {
      return customCarret(order, column);
    },
  },
  {
    dataField: "time",
    text: "Time",
    sortCaret: (order, column) => {
      return customCarret(order, column);
    },
  },
  {
    dataField: "assetstring",
    text: "Auto",
    sort: true,
    sortCaret: (order, column) => {
      return customCarret(order, column);
    },
  },
  {
    dataField: "pinStatus",
    text: "Status",
    sort: true,
    sortCaret: (order, column) => {
      return customCarret(order, column);
    },
  },
  // {
  //   dataField: "username",
  //   text: "gebruiker",
  //   sort: true,
  //   headerSortingStyle,
  // },
];
const MySearch = (props) => {
  const handleClick = () => {
    props.onExport();
  };
  const handleChange = (input) => {
    console.log(input.target.value);
    props.onSearch(input.target.value);
  };
  return (
    <div className="table__filter">
      <input type="text" placeholder="Zoeken..." onChange={handleChange} />
      <a className="button" onClick={handleClick}>
        Exporteren
      </a>
    </div>
  );
};

export default function Table(props) {
  const [image, setImage] = useState(null);
  const [events, setEvents] = useState([]);
  const { company,hasCam } = useAppContext();
  const getPictureRow = async (timestamp, chassis) => {
    console.log(`${timestamp}-${chassis}`);
    let result = await getPictureAWS(chassis, timestamp, company);
    setImage(JSON.parse(result.Payload));
  };

  useEffect(() => {
    let eventsTemp = [];
    console.log(props);
    if (props.eventList) {
      for (let index = 0; index < props.eventList.length; index++) {
        let event = props.eventList[index];
        console.log(event);
        let eventDate = new Date(event.timestamp);
        event.day = eventDate.toLocaleDateString();
        event.time = eventDate.toLocaleTimeString();
        eventsTemp.push(event);
      }
    }
    setEvents(eventsTemp);
  }, [props.eventList]);

  const expandRow = {
    renderer: (row) => (
      <div>
        <img className={classes.Image} src={image} rounded />
      </div>
    ),
    showExpandColumn: true,
    onlyOneExpanding: true,
    expandColumnPosition: "right",
    className: "expandedrow",
    onExpand: async (row, isExpand, rowIndex, e) => {
      const filestamp = row.timeStamp ? row.timeStamp : row.timestamp;
      console.log(filestamp);
      await getPictureRow(filestamp, row.chassis);
    },
    expandHeaderColumnRenderer: ({ isAnyExpands }) => {
      // if (isAnyExpands) {
      return (
        <div>
          <FontAwesomeIcon
            icon={faCamera}
            style={{ fontSize: "1.5em", verticalAlign: "middle" }}
          />{" "}
        </div>
      );
      // }
      //   return <b>+</b>;
    },
    expandColumnRenderer: ({ expanded }) => {
      // if (expanded) {
      return (
        <FontAwesomeIcon
          icon={faCamera}
          style={{ fontSize: "1.5em", verticalAlign: "middle" }}
        />
      );
      //  }
      // return <p>+</p>;
    },
  };

  return (
    <>
      <ToolkitProvider
        keyField="timestamp"
        data={events}
        columns={props.eventPlace ? columnsPlaceEvent : columns}
        search
        exportCSV={{ onlyExportFiltered: true, exportAll: false }}
      >
        {(tableProps) => (
          <>
            <MySearch {...props.searchProps} {...props.csvProps} />
            {hasCam &&
            <BootstrapTable
              classes="table -medium"
              expandRow={expandRow}
              bootstrap4={true}
              className={classes.Table}
              {...tableProps.baseProps}
            />}{!hasCam &&
              <BootstrapTable
              classes="table -medium"
              bootstrap4={true}
              className={classes.Table}
              {...tableProps.baseProps}
            />}
          </>
        )}
      </ToolkitProvider>
    </>
  );
}
