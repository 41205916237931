import React from "react";

export default function PinCard(props) {
  return (
    <>
      <h1>{props.event.chassis}</h1>
      <table class="table -minimal -small">
          <>
            <tr>
              <td>Status</td>
              <td>{props.event.pinStatus}</td>
            </tr>
            <tr>
              <td>Plaats</td>
              <td>{props.event.placeName}</td>
            </tr>
            <tr>
              <td>Dag</td>
              <td>{props.event.day}</td>
            </tr>
            <tr>
              <td>Tijd</td>
              <td>{props.event.time}</td>
            </tr>
          </>
      </table>
      {/* <span
        class="button -transparent -open-model"
        data-model="history"
        onClick={() => {
          props.getHistoryPlace(props.place);
        }}
      >
        <img src={historyLogo} alt="" />
        Historiek plaats bekijken
      </span> */}
    </>
  );
}
