import React, { useState, useEffect } from "react";
//import "./App.css";

import { useAppContext } from "./../libs/contextLib";

import {
  readDataAWS,
addNewPinAWS
} from "./../libs/lambdaLib";



export default function AddPins() {
  const [scanMessage, setScanMessage] = useState("Scan Nieuwe Pin");

  const {
    company,
    readerOpen,
    setReaderOpen,
    scanner,
    isFocus,
    language,
  } = useAppContext();



  useEffect(() => {
    return () => {
      console.log("Going to close");
      if (scanner) scanner.stopScanning();
    };
  }, []);



  useEffect(() => {
    console.log("isFocusReaderopen" + readerOpen);

    if (isFocus) {
      if (readerOpen) {
        freeScanning();
      }
    } else {
      if (readerOpen) {
        scanner.stopScanning();
      }
    }
  }, [isFocus]);

  useEffect(() => {
    console.log("Readeropen" + readerOpen);

    if (readerOpen) {
      freeScanning();
    }
  }, [readerOpen]);

  async function freeScanning() {
    try {
      const result = await scanner.startScanning(-1);
      console.log("New scanned " + result);
      if (result) {
        const resultExistence = await addNewPinAWS(company,result);
        const { statusCode, payload, message } = readDataAWS(resultExistence);
        scanner.writeWebsite(result);
        setScanMessage(message);
        if (statusCode === 404) {
            scanner.feedbackSignal(2, 2);
          } else if (statusCode == 200) {
            scanner.feedbackSignal(0, 1);
        }
        if (isFocus) {
          setTimeout(async () => {
            await freeScanning();
          }, 200);
        }
      }
    } catch (e) {
      if (e !== "" && e !== "stopped") {
        setReaderOpen(false);
        console.log(e);
      }
    }
  }

  


  return (
    <>
      <section>
        <h1>{scanMessage}</h1>
      </section>
    </>
  );
}
