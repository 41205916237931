import React, { useState, useEffect } from "react";
import Routes from "./routes";
import { AppContext } from "./libs/contextLib";
import { Auth } from "aws-amplify";
import { useHistory } from "react-router-dom";
import SideBar from "./Components/SideBar";
import OfflineComp from "./Components/Offline";

import NFCreader from "./libs/NFCbrowser";
import * as routes from "./routes";
import {
  setCredentialsAWSLambda,
  isAdminUserAWS,
  readDataAWS,
  getFlowAWS,
  getAttributesAWS
} from "./libs/lambdaLib";
//import "./App.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSync } from "@fortawesome/free-solid-svg-icons";
import { isMobile } from 'react-device-detect';
import  { Redirect } from 'react-router-dom';

import { Offline, Online } from "react-detect-offline";


let tokenInterval;

let connectedReaderInterval;
export default function App() {
  const [scanner, setScanner] = useState(null);
  const [isAuthenticated, userHasAuthenticated] = useState(false);
  const [hasCam, setHasCam] = useState(true);
  const [hasParking, setHasParking] = useState(false);
  const [isAuthenticating, setIsAuthenticating] = useState(true);
  const [username, setUsername] = useState(null);
  const [company, setCompany] = useState(null);
  const history = useHistory();
  const [isOpeningReader, setIsOpeningReader] = useState(false);
  const [readerOpen, setReaderOpen] = useState(false);
  const [isFocus, setIsFocus] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [userGroup, setUserGroup] = useState(false);
  const [defaultFlow, setDefaultFlow] = useState([]);
  const [attributes,setAttributes] = useState([]);
  const [UUID,setUUID] = useState("");
  const [labelInfo,setLabelInfo] = useState([]);
  const [printer,setPrinter] = useState([]);
  const [hasQRCode,setHasQRCode] = useState(false);
  const [changePlace, setChangePlace]= useState({});
  const [language, setLanguage]= useState("NL");
  const [placeHistory, setPlaceHistory]= useState(true);
  const [assetSummary, setAssetSummary]= useState([]);

  useEffect(() => {
    setScanner(new NFCreader());
    if(isMobile){
      window.location.replace('https://mobile.codek.be')

    }

    onLoad();
    window.addEventListener("focus", () => {
      console.log("focus");

      setIsFocus(true);
    });
    window.addEventListener("blur", () => {
      console.log("blur");
      setIsFocus(false);

      // setIsFocus(false);
      // if (readerOpen) {
      //   scanner.stopScanning();
      // }
    });
    document.addEventListener("visibilitychange", function () {
      if (document.visibilityState === "hidden") {
        setIsFocus(false);
      } else if (document.visibilityState === "visible") {
        //setIsFocus(true);
      }
      console.log(document.visibilityState);
      // Modify behavior...
    });
    // Specify how to clean up after this effect:
    if (document.visibilityState === "hidden") {
      setIsFocus(false);
    } else if (document.visibilityState === "visible") {
      setIsFocus(true);
    }
    console.log(document.querySelector("meta[build-version]").getAttribute("build-version"));

    return () => {
      window.removeEventListener("focus");
      window.removeEventListener("blur");
    };
  }, []);

  useEffect(() => {
    if (!isFocus && readerOpen) {
      scanner.stopScanning();
    } else if (isFocus && !readerOpen && !isOpeningReader && isAuthenticated) {
      console.log("Focuss and reading");
      setIsOpeningReader(true);

      setTimeout(() => {
        connectReader();
      }, 2000);
    }
    if(isAuthenticated){
      checkTokenUser();
    }
  }, [isFocus]);

  useEffect(() => {
    if (isAuthenticated) {
      checkTokenUser();
      startTokenInterval();
      if (isFocus && !readerOpen) {
        setIsOpeningReader(true);
        setTimeout(() => {
          connectReader();
        }, 2000);
      }
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if(company){
      getDefaultFlow();
      getAttributes();

    }

  },[company])


  async function getAttributes () {
    const result = await getAttributesAWS(company);
    const {payload} = readDataAWS(result);
    setChangePlace(payload.changePlace);
    setHasCam(payload.camera);
    setUUID(payload.uuid);
    setHasQRCode(payload.QRcode);
    setHasParking(payload.parking);
    setAttributes(payload.attributes);
    setLabelInfo(payload.label);
    setPrinter(payload.printer);
    setAssetSummary(payload.assetSummary);
    if(payload.placeHistory !== undefined){
      console.log(payload.placeHistory)
      setPlaceHistory(payload.placeHistory);

    }
  }


  const getDefaultFlow = async () => {
    let result = await getFlowAWS(company);
    console.log("Default Flow")
    result = readDataAWS(result).payload;
    if(result) setDefaultFlow(result.flow);
  }

  const startTokenInterval = () => {
    tokenInterval = setInterval(async () => {
      try{
        const cognitoUser = await Auth.currentAuthenticatedUser();
        console.log(cognitoUser);

        const { refreshToken } = cognitoUser.getSignInUserSession();
        cognitoUser.refreshSession(refreshToken, (err, session) => {
          if (Date.now() / 1000 - session.accessToken.payload.auth_time > 90000) {
            handleLogout();
          }
          setCredentialsAWSLambda();
        });
      }
      catch(e){
        handleLogout();
      }

    }, 10000);

  }

  const checkTokenUser = async () => {
    try{

    const cognitoUser = await Auth.currentAuthenticatedUser();
    console.log("cog ");
    console.log(cognitoUser);

    const { refreshToken } = cognitoUser.getSignInUserSession();

    cognitoUser.refreshSession(refreshToken, async (err, session) => {
      if(err){
        handleLogout();
      }
      else if (Date.now() / 1000 - session.accessToken.payload.auth_time > 90000) {
        handleLogout();
      }
      else{
        try{
          await setCredentialsAWSLambda();
          const result = await isAdminUserAWS(username);
          console.log(result);
          setIsAdmin(readDataAWS(result).payload.isAdmin);
          setUserGroup(readDataAWS(result).payload.group);

        }
        catch (e){
          handleLogout();

        }

      }

    });


  }
    catch(e) {
      handleLogout();

    }
  };
  const connectReader = async () => {
    clearInterval(connectedReaderInterval);
    try {
      console.log("Opening Reader");
      console.log(NFCreader.hasNFCplugin());
      console.log(scanner);
      console.log(window.uFR_Request);
      if (scanner) {
        await scanner.openReader();
        setIsOpeningReader(false);
        setReaderOpen(true);
      }

      // connectedReaderInterval = setInterval(async () => {
      //   checkReaderOpen();
      // }, 5000);
    } catch (e) {
      setReaderOpen(false);
      setIsOpeningReader(false);
      //setScanner(new NFCreader());

      console.log("Couldnt open scanner" + e);
    }
  };

  const checkReaderOpen = async () => {
    try {
      await scanner.startScanning(1);
    } catch (e) {
      console.log("Disconnected reader " + e);
      setReaderOpen(false);
      clearInterval(connectedReaderInterval);
    }

    //TODO: change tray icon
  };

  async function onLoad() {
    try {
      const user = await Auth.currentSession();
      console.log("session");
      console.log(user);
      setUsername(user.idToken.payload["cognito:username"]);
      setCompany(user.idToken.payload["custom:company"]);
      userHasAuthenticated(true);

    } catch (e) {
      handleLogout();
      if (e !== "No current user") {
        //  alert(e);
      }
    }
    setIsAuthenticating(false);
    await setCredentialsAWSLambda();
  }

  async function handleLogout() {
    try {
      await Auth.signOut();
      setCredentialsAWSLambda();
    } catch (e) {
      console.log(e);
    } finally {
      if(tokenInterval){
        clearInterval(tokenInterval)
      }
      userHasAuthenticated(false);
      console.log("history");
      console.log(history);
      history.push(routes.HOME);
    }
  }

  return (
    <>
    {/* <Online> */}
    <AppContext.Provider
        value={{
          isAuthenticated,
          userHasAuthenticated,
          username,
          company,
          scanner,
          readerOpen,
          setUsername,
          setCompany,
          setReaderOpen,
          isFocus,
          defaultFlow,
          setUUID,
          UUID,
          setAttributes,
          attributes,
          changePlace,
          setChangePlace,
          language,
          placeHistory,
          hasQRCode,
          labelInfo,
          printer,
          assetSummary,
          userGroup,
          hasParking,
        hasCam       }}
      >
        {isAuthenticated && (
          <>
            <SideBar isAdmin={isAdmin} handleLogout={handleLogout} />
            <main>
              <header className="header" hidden={!isAuthenticated}>
                <button
                  className={`status ${
                    readerOpen && isFocus ? "-success" : ""
                  }`}
                  onClick={connectReader}
                >
                  {" "}
                  <div className="status__text">
                    <div className="status__dot"></div>
                    RFID
                  </div>{" "}
                  {isOpeningReader && (
                    <FontAwesomeIcon icon={faSync} className="spinning" />
                  )}
                </button>
              </header>

              <div className="container">
                <Routes />
              </div>
            </main>
          </>
        )}
        {!isAuthenticated && <Routes />}
      </AppContext.Provider>
      {/* </Online> */}
     {/* <Offline>
       <OfflineComp/>
     </Offline> */}
    </>
  );
}
