import React, { memo } from "react";

function showButtons(props) {
  let buttons = [];
  let title = [];
  if(props.rowName.length > 0){
    buttons.push(
      <div className={'zone__title'} >
      <span className={`board__number`}>{props.rowName[props.row]} </span>
  </div>)
      title.push(
        <div className={'zone__title'} >
        <span className={`board__number`}> </span>
    </div>
    )
  }

  for (let index = 0; index < props.columnName.length; index++) {
    let elIndex = props.columnName[index];

    if(props.formatter && props.formatter.length > 0 && props.formatter[0].action === "prefix"){

      elIndex = `${props.formatter[0].substring}${"0".repeat(3-elIndex.toString().length)}${elIndex}`
      console.log(elIndex)
    }
    else if (props.formatter && props.formatter.length > 0 && props.formatter[0].action === "columnrow"){

      elIndex = `${props.columnName[index]} ${props.rowName[props.row]}`
      console.log(elIndex)
    }
    let buttonColor = "";

    if(Object.keys(props.carList).length > 0){
    if(props.carList[ elIndex ]&&  props.carList[ elIndex ].length <  props.max) buttonColor= "-green";
    else if(props.carList[ elIndex ]&&  props.carList[ elIndex ].length >= props.max) buttonColor= "-error";
    // else if(props.buttons[index].pinID === "u"){
    //    buttonColor= "-error";
    // };
    }
    title.push(
    <div className={'zone__title'} >
          <span className={`board__number`}>{props.columnName[index]} </span>
      </div>
    )
    buttons.push(
    <>

      <div className={`zone__pin  ${buttonColor}` }>
          <span className={`board__number`} onClick={ props.carList[ elIndex ]?() => {props.handleClickPlace(props.carList[ elIndex], elIndex)}:() => {}}>{props.carList[ elIndex ]? props.carList[ elIndex ].length:0} </span>
      </div></>
    );
  }
return (  <>{props.row == 0 && <div className="zone__row">{title}</div> }<div className="zone__row">{buttons}</div></> );
}

function ZonesRow(props) {
  return <>{showButtons(props)}</>;
}

export default  ZonesRow = memo( ZonesRow);
