import React, { memo, useEffect, useState } from "react";
import ButtonRow from "./ButtonRow";
import EventTable from "../EventsTable";
import { readDataAWS, getEventsPlaceAWS } from "../../libs/lambdaLib";
import { useAppContext } from "../../libs/contextLib";
import PinCard from "./PinCard";
import crossIcon from "./../../img/icon-cross.svg";

function ButtonGrid(props) {
  const [show, setShow] = useState(false);
  const [showCard, setShowCard] = useState(false);
  const [clickedPin, setClickedPin] = useState({});
  const [eventList, setEventList] = useState(false);

  const [place, setPlace] = useState("");
  const { company,assetSummary } = useAppContext();

  useEffect(() => {
    console.log("Mounting grid");
    console.log(props.cabinetKey);
  }, []);

  useEffect(() => {
    console.log("Rerender Buttongrid");
  });

  const handleClose = () => {
    setShow(false);
  };

  const handleClickPlace = async (pin,pos) => {
    console.log("Handle Click Place");
    console.log(pin);
    pin.place = pos;
    setClickedPin(pin);
    setShowCard(true);
  };

  const getHistoryPlace = async (place) => {
    console.log(props.cabinetKey + " " + place);
    //setPlace(props.cabinetKey + "-" + place);
    const result = await getEventsPlaceAWS(clickedPin.sk, company);

    const { statusCode, payload, message } = readDataAWS(result);
    console.log(payload);
    console.log("Eventlist print undefiend??");
    let events = payload.events.map((event) => {
      let asset = payload.assets[event.chassis];
      event.chassis = asset.chassis;
      event.assetstring = "";
      console.log("AssetSummary");
      console.log(asset);
      console.log(assetSummary)
      if(asset.attributes){
        for (let i = 0; i < assetSummary.length; i++) {
          event.assetstring = `${event.assetstring} ${asset.attributes[assetSummary[i]]}`;
        }
      }
      else event.assetstring = `${asset.brand} ${asset.model} ${asset.type}`;
      return event;
    });
    setEventList(events);
    setShow(true);
  };

  const generateRows = (cabinetList, rows, columns) => {
    let pinRow = [];
    if (!cabinetList || cabinetList.length === 0) return;
    for (let index = 0; index < rows; index++) {
      // console.log(cabinetList.slice(index * 10 + 0, index * 10 + 10));
      pinRow.push(
        <ButtonRow
          col={columns}
          row={index}
          buttons={cabinetList.slice(index * 10 + 0, index * 10 + 10)}
          handleClickPlace={handleClickPlace}
        />
      );
    }
    return pinRow;
  };

  return (
    <>
      <div class={`model -large ${show ? "-active" : ""}`} data-model="history">
        <div class="model__container">
          <h1>{place}</h1>
          <EventTable eventList={eventList} eventPlace />
          <img
            src={crossIcon}
            alt=""
            class="model__cross"
            onClick={handleClose}
          />
        </div>
      </div>

      {/* <div onClick={(e) => e.stopPropagation()}>
        <Modal
          dialogClassName={classes.customDialog}
          show={show}
          onHide={handleClose}
        >
          <Modal.Header closeButton>
            <Modal.Title>{place}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <EventTable eventList={eventList} eventPlace />
            {/* eventList={eventList} pin={clickedCar} />
          </Modal.Body>
          <Modal.Footer></Modal.Footer>
        </Modal>
      </div> */}
      <h1 class="-color-1">{props.cabinetKey.placeName}</h1>
      <p>
        {props.cabinetKey.city}
      </p>
      <div className="board">
        <div class="board__holder">
        {/* <div class="board__holder_row"> */}
          {generateRows(props.cabinetList, props.rows, props.columns)}
          {/* </div> */}
        </div>

          {showCard && (
            <div class="board__card">
            <PinCard getHistoryPlace={getHistoryPlace} sensor={clickedPin} />
            </div>
          )}
      </div>
    </>
  );
}

export default ButtonGrid = memo(ButtonGrid);
