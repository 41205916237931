import React from "react";
import { Route, Switch } from "react-router-dom";
import Home from "./Home";
import CompanyPinList from "./Components/CompanyPinList";
import Cabinet from "./Components/Cabinet/Cabinet";
import Parking from "./Components/Parking/Parking";
import Login from "./Components/Login";
import Reports from "./Components/Reports";
import Assign from "./Components/Assign";
import UsersTable from "./Components/UsersTable";
import BadgeTable from "./Components/BadgeTable";
import Settings from "./Components/Settings";
import NotFound from "./Components/NotFound";
import AuthenticatedRoute from "./Components/AuthenticatedRoute";
import UnauthenticatedRoute from "./Components/UnauthenticatedRoute";
import AddPins from "./Components/AddPins";

export const CABINET = "/cabinet";
export const TABLE = "/list";
export const USERS = "/users";
export const BADGES = "/badges";
export const REPORTS = "/reports";
export const MAP = "/";
export const PARKING = "/parking";
export const NFC = "/assign";
export const HOME = "/";
export const LOGIN = "/login";
export const SETTINGS = "/settings";
export const NEWPIN = "/NEWPIN";

export default function Routes() {
  return (
    <Switch>
      <AuthenticatedRoute exact path={HOME}>
        <Home />
      </AuthenticatedRoute>
      <AuthenticatedRoute exact path={TABLE}>
        <CompanyPinList />
      </AuthenticatedRoute>
      <AuthenticatedRoute exact path={CABINET}>
        <Cabinet />
      </AuthenticatedRoute>
      <AuthenticatedRoute exact path={PARKING}>
        <Parking/>
      </AuthenticatedRoute>
      <AuthenticatedRoute exact path={REPORTS}>
        <Reports/>
        </AuthenticatedRoute>
      <AuthenticatedRoute exact path={NFC}>
        <Assign />
      </AuthenticatedRoute>
      <AuthenticatedRoute exact path={USERS}>
        <UsersTable />
      </AuthenticatedRoute>
      <AuthenticatedRoute exact path={BADGES}>
        <BadgeTable />
      </AuthenticatedRoute>
      <AuthenticatedRoute exact path={SETTINGS}>
        <Settings/>
      </AuthenticatedRoute>
      <AuthenticatedRoute exact path={NEWPIN}>
        <AddPins/>
      </AuthenticatedRoute>
      <UnauthenticatedRoute exact path={LOGIN}>
        <Login />
      </UnauthenticatedRoute>
      <Route exact path="/reload" component={null}/>
      <Route>
        <NotFound />
      </Route>
    </Switch>
  );
}
