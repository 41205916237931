import React, { useState, useEffect } from "react";
import { useAppContext } from "../../libs/contextLib";
import ParkingGrid from "./ParkingGrid";
import ZonesTable from "./ZonesTable";

import {
  readDataAWS,
  getCarsParkingAWS,
  getChangePlaceSettingsAWS,
} from "../../libs/lambdaLib";


export default function Parking() {

  const [rows, setRows] = useState(20);
  const [columns, setColumns] = useState(20);
  const [carList, setCarList] = useState([]);
  const [parking, setParking] = useState("Parkeergarage");
  const [newplaceFormatter,setNewplaceFormatter] = useState([
    {
      "inputFormatter": [
       {
        "action": "ADD",
        "pos": 1,
        "substring": "."
       },
       {
        "action": "MAX_LENGTH",
        "value": 5
       }
      ],
      "inputType": "string",
      "parking": "Parkeergarage",
      "validation": [
       {
        "action": "LENGTH",
        "text": "4 cijfers",
        "value": 5
       },
       {
        "action": "starts",
        "pos": 0,
        "text": "1ste cijfer, 0 1 of 2",
        "values": [
         "0",
         "1",
         "2"
        ]
       },
       {
        "action": "between",
        "pos": 0,
        "text": "Tussen 0.001-0.186, 1.001-1.183 of 2.001-2.182",
        "values": [
         [
          "0.001",
          "0.186"
         ],
         [
          "1.001",
          "1.183"
         ],
         [
          "2.001",
          "2.182"
         ]
        ]
       }
      ],
      "visualizer": [
        {
          "amount": 186,
          "columns": 20,
          "formatter": [
           {
            "action": "prefix",
            "substring": "0."
           }
          ],
          "title": "0",
          "type": "place"
         },
         {
          "amount": 183,
          "columns": 20,
          "formatter": [
           {
            "action": "prefix",
            "substring": "1."
           }
          ],
          "title": "1",
          "type": "place"
         },
         {
          "amount": 182,
          "columns": 20,
          "formatter": [
           {
            "action": "prefix",
            "substring": "2."
           }
          ],
          "title": "2",
          "type": "place"
         }
      ]
     },
     {
      "inputFormatter": [
      ],
      "inputType": "toggleGroup",
      "parking": "P4 - Zegswaard",
      "toggleGroups": [
       {
        "title": "Rij",
        "values": [
         1,
         2,
         3,
         4,
         5,
         6,
         7,
         8,
         9,
         10
        ]
       },
       {
        "title": "Positie",
        "values": [
         "Begin",
         "Midden",
         "Eind"
        ]
       }
      ],
      "toggles": 2,
      "validation": [
      ],
      "visualizer": [
       {
        "title": "Zones",
        "type": "zones",
        "columnName": [
          "1",
          "2",
          "3",
          "4",
          "5",
          "6",
          "7",
          "8",
          "9",
          "10"
         ],
         "max": 50,
         "rowName": [
           "Begin",
           "Midden",
           "Einde"
         ],
         "formatter": [
         ]
       }
      ]
     },
     {
      "inputFormatter": [
      ],
      "inputMax": 60,
      "inputMin": 1,
      "inputType": "number",
      "parking": "TK",
      "validation": [
       {
        "action": "LENGTH",
        "text": "2 cijfers",
        "value": 1
       },
       {
        "action": "between",
        "pos": 0,
        "text": "Tussen 1-60 ",
        "values": [
         [
          "1",
          "60"
         ]
        ]
       }
      ],
      "visualizer": [
       {
        "amount": 90,
        "columns": 20,
        "formatter": [
        ],
        "title": "",
        "type": "place"
       }
      ]
     }
  ]);
  const { company } = useAppContext();
  const [carOccupation,setCarOccupation] = useState([]);
  const [vizualizer, setVizualizer] = useState([
    {
    "title": "Zones",
    "type": "zones",
    "columnName": [
      "Schans 01",
      "Schans 12"
     ],
     "max": 20,
     "rowName": [
     ],
     "formatter": [
     ]
  },
  {
    "title": "Zones",
    "type": "zones",
    "columnName": [
      "1",
      "2",
      "3",
      "4",
      "5",
      "6",
      "7",
      "8",
      "9",
      "10"
     ],
     "max": 20,
     "rowName": [
       "Begin",
       "Midden",
       "Einde"
     ],
     "formatter": [
     ]
  }
    ,
    {
     "amount": 186,
     "columns": 20,
     "formatter": [
      {
       "action": "prefix",
       "substring": "0."
      }
     ],
     "title": "0",
     "type": "place"
    },
    {
     "amount": 183,
     "columns": 20,
     "formatter": [
      {
       "action": "prefix",
       "substring": "1."
      }
     ],
     "title": "1",
     "type": "place"
    },
    {
     "amount": 182,
     "columns": 20,
     "formatter": [
      {
       "action": "prefix",
       "substring": "2."
      }
     ],
     "title": "2",
     "type": "place"
    }
   ]);

  useEffect(() => {
    getChangePlaceSettings();

  }, []);
  useEffect(() => {
    if(newplaceFormatter.length > 0){
      onLoad();
    }

  }, [newplaceFormatter]);

  async function  getChangePlaceSettings(){
    try{
      let data = await getChangePlaceSettingsAWS(company);
      const { payload } = readDataAWS(data);
      console.log(payload.data.default)
      console.log(newplaceFormatter.length)

      setNewplaceFormatter(payload.data.default);
    }
    catch(e){

    }
  };

  const onLoad = async () => {
    if(newplaceFormatter.length === 1){
      const result = await getCarsParkingAWS(company,newplaceFormatter[0].parking);
      const { statusCode, payload, message } = readDataAWS(result);
      let carArray = {};
      for (let i = 0; i < payload.length; i++) {
        const element = payload[i];
        if(carArray[element.placeName]){
          carArray[element.placeName].push(payload[i]);
        }
        else{
          carArray[element.placeName] = [payload[i]];
        }
        };
      setCarList(carArray);
      console.log(carArray);
    }
    else{
      let parkingOccupation = [];
      for(let i=0;i<newplaceFormatter.length;i++){
        const result = await getCarsParkingAWS(company,newplaceFormatter[i].parking);
        const { statusCode, payload, message } = readDataAWS(result);
        parkingOccupation.push(payload.length);

      }
      setCarOccupation(parkingOccupation);
    }
  }

  const clickParking = async (parking,index) => {
    setParking(parking)
    setVizualizer(newplaceFormatter[index].visualizer)
    setNewplaceFormatter([newplaceFormatter[index]])
  };

  const parkingCard = () => {
    let result = [];
    result.push(
      newplaceFormatter.map((parking,index) => {
        return (
          <div class="overview__item">
            <h1 class="overview__title">{parking.parking}</h1>
             <p>
              {carOccupation[index]}
              </p>
            <a
              class="button"
              onClick={() => {
                clickParking(parking.parking, index);
              }}
            >
              Open Parking
            </a>
          </div>
        );
      })
    );
    return <div className="overview">{result}</div>;
  };

  const generateParkings = () => {

    let parkings = [];

    for (let i = 0; i < vizualizer.length; i++) {
      const singleVisualizer= vizualizer[i];

      if(singleVisualizer.type === "place"){
      parkings.push(
        <>{singleVisualizer.title}
        <ParkingGrid
        amount={singleVisualizer.amount}
        columns={singleVisualizer.columns}
        carList={carList}
        formatter={singleVisualizer.formatter}
      /> </>
      )
      }
      else if(singleVisualizer.type === "zones"){
        parkings.push(
          <>{singleVisualizer.title}
            <ZonesTable
                    columnName={singleVisualizer.columnName}
                    rowName={singleVisualizer.rowName}
                    carList={carList}
                    formatter={singleVisualizer.formatter}
                    max={singleVisualizer.max}
      /></>);
    }
  }
    return parkings
  }

  return (
    <section>

       {newplaceFormatter.length  == 1 ?
         generateParkings()
       : parkingCard()}
    </section>
  );
}
