
export default class NFCreader {
  constructor() {
    this.stopScan = false;
    this.isOpen = false;
    this.tryingToStopScan = false;
  }

  openReader = async () => {
    return new Promise(async (resolve, reject) => {
      console.log('Gonna open it')
      setTimeout(() => {
        reject("10 s timeout");
      }, 10000);
      if (typeof window.uFR_Request !== "undefined") {
        let result = await window.uFR_Request("ReaderOpen").catch((e) => {
          reject(e);
        });
        const status = result.Status;
        console.log(status)
        if (status === "[0x00 (0)] UFR_OK") {
          await window.uFR_Request("ReaderUISignal 1 1");
          this.isOpen = true;
          resolve();
        } else {
          reject(status);
        }
      } else {
        reject("No extension");
      }
    });
  };

  writeWebsite = async(uid) => {
    return new Promise(async (resolve, reject) => {
      console.log('Going to write')
      let str_data = `mobile.codek.be/${uid}`;

      const write_length = str_data.length + 7;
      let data = new Buffer.alloc(write_length);
      let bytes_written = new Buffer.alloc(2);
      bytes_written.type = "ushort"
      let temp = [];
      temp.push(0x03,0x49,0xD1,0x01,0x45,0x55,0x03);
      for (let i = 0; i < write_length; i++)
      {
        temp.push(str_data.charCodeAt(i));
      }
      for (let i = 0; i < temp.length; i++)
      {
        data[i] = temp[i];
      }

      setTimeout(() => {
        reject("10 s timeout");
      }, 10000);
      if (typeof window.uFR_Request !== "undefined") {
        let result = await window.uFR_Request(`LinearWrite ${data} 0 ${write_length} ${bytes_written} 0x60 0`).catch((e) => {
          reject(e);
        });
        const status = result.Status;
        console.log(status)
        if (status === "[0x00 (0)] UFR_OK") {
          await window.uFR_Request("ReaderUISignal 1 1");
          this.isOpen = true;
          resolve();
        } else {
          reject(status);
        }
      } else {
        reject("No extension");
      }
    });
  }

  feedbackSignal = async (led, beep) => {
   try{
      await window.uFR_Request(`ReaderUISignal ${led} ${beep}`);
    }
    catch(e){
      
    }
  };

  startScanning(maxTries) {
    // If still earlier scanning, stop?
    this.stopScan = false;
    this.tryingToStopScan = false;
    clearTimeout(this.interval);

    console.log("going to scan");
    return new Promise(async (resolve, reject) => {
      let i = 1;
      // TODO: settimeout loop!!

      const scanOnce = () => {
        this.interval = setTimeout(async () => {
          if (this.stopScan) {
            clearTimeout(this.interval);
            this.stopScan = false;
            this.tryingToStopScan = false;
            reject("stopped");
          } else if (i > maxTries && maxTries > 0) {
            clearTimeout(this.interval);
            resolve();
          } else {
            if (typeof window.uFR_Request !== "undefined") {

            let result = await window.uFR_Request("GetCardIdEx");
            const status = result.Status;

            if (status.includes("UFR_OK")) {
              clearTimeout(this.interval);
              if (result.CardUid) {
                let uid_str = result.CardUid.slice(2).toLowerCase();
                resolve(uid_str);
              } else {
                resolve();
              }
            } else if (status.includes("164") || status.includes("161")) {
              clearTimeout(this.interval);
              reject(status);
            } else if (status.includes("UFR_NO_CARD")) {
              i = i + 1;
              scanOnce();
            } else {
              reject(status);
            }
          }
          else{
            reject("No extension");

          }

          }
        }, 200);
      };

      scanOnce();
    });
  }

  static hasNFCplugin(){
    if (typeof window.uFR_Request !== "undefined") {
      return true
    }
    else {
      return false
    }
  }

  stopScanning() {
    //TODO: Call this when stopping
    console.log("stopSCanning");
    if (!this.tryingToStopScan) {
      this.stopScan = true;
      this.tryingToStopScan = true;
    }
  }
}
