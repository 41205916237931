import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { readDataAWS, getTasksInProgressAWS, setTaskPersonAWS, deleteTaskPersonAWS} from "../../libs/lambdaLib";
import { useAppContext } from "./../../libs/contextLib";
import crossIcon from "./../../img/icon-cross.svg";
import { Auth, Signer } from "aws-amplify"
import { w3cwebsocket as W3CWebSocket } from "websocket"


const events = {
  INSERTED_PIN_INFO:"INSERTED_PIN_INFO",
  REMOVED_PIN_INFO:"REMOVED_PIN_INFO"
}


let wsClient = null;

// fake data generator
const getItems = (count, offset = 0) =>
  Array.from({ length: count }, (v, k) => k).map((k) => ({
    id: `item-${k + offset}-${new Date().getTime()}`,
    content: `item ${k + offset}`
  }));

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const formatTimeDiff = (element) => {
  console.log(element);
  const dateDiff = new Date() - new Date(element.lastUpdate);
  const h = Math.floor(dateDiff/(1000 * 60 * 60));
  const m = Math.floor((dateDiff-h*60*60*1000)/(1000 * 60));
  return `${h}h${m}m`
}
/**
 * Moves an item from one list to another list.
 */
const move = (source, destination, droppableSource, droppableDestination) => {
  const sourceClone = Array.from(source);
  const destClone = Array.from(destination);
  const [removed] = sourceClone.splice(droppableSource.index, 1);

  destClone.splice(droppableDestination.index, 0, removed);

  const result = {};
  result[droppableSource.droppableId] = sourceClone;
  result[droppableDestination.droppableId] = destClone;

  return result;
};
const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",
  padding: grid * 2,
  margin: `0 0 ${grid}px 0`,

  // change background colour if dragging
  background: isDragging ? "#efeff6" : "#e3e3ed",

  // styles we need to apply on draggables
  ...draggableStyle
});
const getListStyle = (isDraggingOver) => ({
  background : "white",
  padding: grid
});

const userList = ["Hans", "Jan", "Jaap", "Kevin", "Maarten"]

export default function TaskList(props) {
  const [state, setState] = useState([[], []]);
  const [inProgressTasks,setInProgressTasks] = useState([])
  const { company, username, isFocus } = useAppContext();
  //const [userList, setUserList] = useState([]);
  const [dropdownClass, setDrowndownClass] = useState("dropdown__assign");
  const [selectedUser, setUser] = useState(null);
  const [showAddUserModal, setShowAddUserModal] = useState(false);
  const [activeTask,setActiveTask] = useState(null);
  const [taskList, setTaskList] = useState(null);
  const [wsMessage,setWsMessage] = useState({});

  useEffect(() => {

    setState([props.cabinetList.filter((element) => !("assignedUser" in element.pin) ), props.cabinetList.filter((element) => "assignedUser" in element.pin )]);
    console.log(props);
    getTasksInProgress();
    let list = props.cabinetList.filter((cabinetplace) => {
      return Object.keys(cabinetplace.pin).length > 0
    });
    list.sort((a,b) => {
      return -a.lastUpdate.localeCompare(b.lastUpdate);
    })
    setTaskList(list);
    loadAWS();

  },[])

  useEffect(()=> {
    if(wsClient){
      console.log("going to connect focus TAB closure "  + wsClient.readyState)

      if(isFocus && wsClient.readyState !== 0 &&  wsClient.readyState !== 1){
        wsClient.close();
        console.log("going to connect focus")
        loadAWS();
      }
    }


  },[isFocus])

  useEffect(() => {
    if(wsMessage.action){
      if(wsMessage.action === "assignUser"){
        moveAssignedUser(wsMessage.selectedUser,wsMessage.activeTask);
      }
      else if(wsMessage.action === "deleteUser"){
        moveRemoveAssignedUser(wsMessage.source,wsMessage.destination);

      }
      else if(wsMessage.action === "pinEvent"){
        console.log("pinevent");
        console.log(wsMessage)
        pinEvent(wsMessage.updatedPin,wsMessage.updatedPin.event);

      }
    }

  },[wsMessage])

  const loadAWS = async () => {
    const credentials = await Auth.currentCredentials()

    const accessInfo = {
      access_key: credentials.accessKeyId,
      secret_key: credentials.secretAccessKey,
      session_token: credentials.sessionToken,
    }

      const wssUrl = "wss://d9n8qpwnj9.execute-api.eu-central-1.amazonaws.com/dev"
      const signedUrl = Signer.signUrl(wssUrl, accessInfo);
      wsClient = new W3CWebSocket(signedUrl);

      wsClient.onerror = function () {
        console.log("[client]: Connection Error")
      }

      wsClient.onopen = function () {
        console.log("[client]: WebSocket Client Connected")
        wsClient.send(JSON.stringify({action:'confirmConnection',company,placeName:props.cabinetKey.stringKey}))
      }

      wsClient.onclose = function () {
        console.log("[client]: Client Closed")
      }

      wsClient.onmessage = function (e) {
        if (typeof e.data === "string") {
          setWsMessage(JSON.parse(e.data).message);
          console.log("Received: '" + JSON.parse(e.data) + "'")
        }
      }


  }
  const getTasksInProgress = async () => {
    const result = await getTasksInProgressAWS(company,props.cabinetKey.stringKey);
    console.log("in progress")
    console.log(readDataAWS(result).payload)
    setInProgressTasks(readDataAWS(result).payload);
  }


  function onDragEnd(result) {
    const { source, destination } = result;
    console.log("test")
    console.log(source)
    console.log(destination)
    // dropped outside the list
    if (!destination) {
      return;
    }

    moveRemoveAssignedUser(source,destination);
    if(wsClient){
      wsClient.send(JSON.stringify({action:'deleteUser',company,source,destination,placeName:props.cabinetKey.stringKey}))
    }
  }

  // const getUsers = async () => {
  //   const result = await listUsersAWS(company);
  //   setUserList(readDataAWS(result).payload);
  // };

  const removeAssignedUser = async (chassis) => {
    await deleteTaskPersonAWS(company,chassis)
  }

  const assignUser  = async () => {
    console.log(selectedUser);
    setShowAddUserModal(false);
    moveAssignedUser(selectedUser,activeTask);
    await setTaskPersonAWS(company,activeTask.pk,selectedUser);

    setUser(null);
    setActiveTask(null);
    if(wsClient){
      wsClient.send(JSON.stringify({action:'assignUser',company,activeTask,selectedUser,placeName:props.cabinetKey.stringKey}))
    }

  }

  const moveAssignedUser = (user,task) => {
    const result = move(state[0], state[1], {droppableId: "0",
    index: task.index}, {droppableId: "1",
    index: state[1].length});
    const newState = [...state];
    newState[0] = result[0];
    newState[1] = result[1];
    console.log(newState[1][newState[1].length-1]);
    newState[1][newState[1].length-1].pin.assignedUser = user;
    console.log(newState.filter((group) => group.length));

    setState(newState);
  }

  const moveRemoveAssignedUser = (source,destination) => {
    const sInd = +source.droppableId;
    const dInd = +destination.droppableId;
    //if(sInd === 0 && dInd === 1) setShowAddUserModal(true)
    if (sInd === dInd) {
      const items = reorder(state[sInd], source.index, destination.index);
      const newState = [...state];
      newState[sInd] = items;
      setState(newState);
    } else {
      const result = move(state[sInd], state[dInd], source, destination);
      const newState = [...state];
      newState[sInd] = result[sInd];
      newState[dInd] = result[dInd];
      console.log("trying to remove")
      console.log(newState[dInd])
      delete newState[dInd][destination.index].pin.assignedUser;
      removeAssignedUser(newState[dInd][destination.index].pin.pk)
      console.log(newState);
      console.log(newState.filter((group) => group.length));

      setState(newState);
    }
  }

  const pinEvent = (updatedPin,event) => {

    if(event === events.INSERTED_PIN_INFO){
      console.log("events.INSERTED_PIN_INFO")
      setInProgressTasks((prev) => {
        const oldState = [...prev];
        let newFilter = oldState.filter((prevPin) => prevPin.pk !==updatedPin.data.pk)
         return [...newFilter]
        })
        const newState = [...state];
        newState[0].push({pin:updatedPin.data});

        setState(newState);
    }
    else if(event === events.REMOVED_PIN_INFO){
      console.log("events.REMOVED_PIN_INFO")
      let element = state[1].filter((task) =>  task.pin.pk === updatedPin.data.pk );
      let updatedPinData = updatedPin.data;
      if(element.length === 1){
        const filteredArray= state[1].filter((task) =>  task.pin.pk !== updatedPin.data.pk );
        const newState = [...state];
        newState[1]=filteredArray
        console.log(newState.filter((group) => group.length));
        setState(newState);

      }
       element = state[0].filter((task) =>  task.pin.pk === updatedPin.data.pk );
      if(element.length === 1){
        const filteredArray= state[0].filter((task) =>  task.pin.pk !== updatedPin.data.pk );
        const newState = [...state];
        newState[0]=filteredArray
        console.log(newState.filter((group) => group.length));
        setState(newState);

      }
      console.log(element);
      setInProgressTasks((prev) => [...prev, {...updatedPinData}])

    }

  }

  const onClickUserSelector = (e) => {
    console.log(e.target.value);
    setDrowndownClass("dropdown__assign");
    setUser(e.target.value);
  };

  const onClickUserDropdown = () => {
    if (dropdownClass === "dropdown__assign") setDrowndownClass("dropdown__assign -open");
    else setDrowndownClass("dropdown__assign");
  };

  return (
    <>
         <div
        class={`model ${showAddUserModal ? "-active" : ""}`}
      >
        <div class="model__container">
          <h1>Taak toewijzen</h1>
          {activeTask && <h3>{activeTask.pk}</h3>}
          <div
      className={dropdownClass}
      role="group"
      onClick={onClickUserDropdown }
      onChange={(e) => onClickUserSelector(e)}
    >
      <div className="dropdown__list__assign">
        {userList.map((user) => {
          const isSelect = user === `${selectedUser}`;
          return (
            <div className="dropdown__option__assign">
              <input
                name="max"
                type="radio"
                value={user}
                id={user}
                checked={isSelect}
              />
              <label for={user}>{user}</label>
            </div>
          );
        })}
      </div>
    </div>

          <button
            class={`button -wide`}
            onClick={assignUser}
          >
            Toewijzen
          </button>
          <img
            src={crossIcon}
            alt=""
            class="model__cross"
            onClick={() => {
              setShowAddUserModal(false);
              setActiveTask(null);
            }}
          />
        </div>
      </div>
      {/* <div class="form__control toggle">
                                    <input type="checkbox" id="receive-notifications" />
                                    <label for="receive-notifications">
                                        Notificaties ontvangen
                                        <div class="toggle__switch">
                                            <span class="toggle__check"></span>
                                        </div>
                                    </label>
                                </div> */}
      <div className="overview">
        <DragDropContext  onDragEnd={onDragEnd}>
          <div className="tasklist__item">
        <h4  class="overview__title" >Niet toegewezen</h4>
          <Droppable  key={0} droppableId={`${0}`}
>
              {(provided, snapshot) => (
                <div
                  ref={provided.innerRef}
                  style={getListStyle(snapshot.isDraggingOver)}
                  {...provided.droppableProps}
                >
                  {state[0].map((item, index) => {
                                        console.log("item")
                    console.log(item)
                    return (<Draggable
                      key={item.sk}
                      draggableId={item.sk}
                      index={index}
                     isDragDisabled={true}
                    >
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={getItemStyle(
                            snapshot.isDragging,
                            provided.draggableProps.style
                          )}
                        >
                          <div
                            style={{
                              position: "relative",
                              justifyContent: "space-around"
                            }}
                          >
                            <h3>{item.pin.pk}</h3>
                            <p>
                            {item.pin.brand},
                            {item.pin.model}
                            </p>
                            <h4>Plaats: {item.pin.place}</h4>
                            <button
                              className = "tasklist__button"
                              type="button"
                              onClick={() => {
                                setShowAddUserModal(true)
                                setActiveTask({pk: item.pin.pk,
                                              row: 1,
                                            index})
                                // const newState = [...state];
                                // newState[0].splice(index, 1);
                                // setState(
                                //   newState
                                // );
                              }}
                            >
                              Wijs toe
                            </button>
        <div class="tasklist__time">
              {formatTimeDiff(item.pin)}
              <br />
            </div>
                          </div>

                        </div>
                      )}
                    </Draggable>)

                            })}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
            </div>

            <div  className="tasklist__item">
            <h4  class="overview__title" >Toegewezen</h4>
          <Droppable  key={1} droppableId={`${1}`}>
              {(provided, snapshot) => (
                <div
                  ref={provided.innerRef}
                  style={getListStyle(snapshot.isDraggingOver)}
                  {...provided.droppableProps}
                >
                  {state[1].map((item, index) => (
                    <Draggable
                      key={item.sk}
                      draggableId={item.sk}
                      index={index}
                    >
                      {(provided, snapshot) => (
                         <div
                         ref={provided.innerRef}
                         {...provided.draggableProps}
                         {...provided.dragHandleProps}
                         style={getItemStyle(
                           snapshot.isDragging,
                           provided.draggableProps.style
                         )}
                       >
                         <div
                           style={{
                             position: "relative",
                             justifyContent: "space-around"
                           }}
                         >
                           <h3>{item.pin.pk}</h3>
                           <p>
                           {item.pin.brand},
                           {item.pin.model}
                           </p>
                           <h4>Plaats: {item.pin.place}</h4>
                            <button
                             className = "tasklist__button -small"
                             type="button"
                              disabled
                           >
                             {item.pin.assignedUser}
                           </button>
       <div class="tasklist__time">
             {formatTimeDiff(item.pin)}
             <br />
           </div>
                         </div>

                       </div>
                      )}
                    </Draggable>
              ))}
                  {provided.placeholder}
                </div>
               )}
            </Droppable>
            </div>
            <div  className="tasklist__item">
            <h4  class="overview__title" >In behandeling</h4>
          <Droppable  key={2} droppableId={`${2}`} isDropDisabled={true}>
              {(provided, snapshot) => (
                <div
                  ref={provided.innerRef}
                  style={getListStyle(snapshot.isDraggingOver)}
                  {...provided.droppableProps}
                >
                  {inProgressTasks.map((item, index) => (
                    <Draggable
                      key={item.sk}
                      draggableId={item.sk}
                      index={index}
                      isDragDisabled={true}

                    >
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={getItemStyle(
                            snapshot.isDragging,
                            provided.draggableProps.style
                          )}
                        >
                                                   <div
                           style={{
                             position: "relative",
                             justifyContent: "space-around"
                           }}
                         >
                           <h3>{item.pk}</h3>
                           <p>
                           {item.brand},
                           {item.model},
                           </p>
                            <button
                             className = "tasklist__button -small"
                             type="button"
                              disabled
                           >
                             {item.assignedUser}
                           </button>
       <div class="tasklist__time">
             {formatTimeDiff(item)}
             <br />
           </div>
                         </div>
                        </div>
                      )}
                    </Draggable>
              ))}
                  {provided.placeholder}
                </div>
               )}
            </Droppable>
            </div>
            </DragDropContext>
            </div>
</>
           // {state.map((el, ind) => (
          //   <Droppable key={ind} droppableId={`${ind}`}>
          //     {(provided, snapshot) => (
          //       <div
          //         ref={provided.innerRef}
          //         style={getListStyle(snapshot.isDraggingOver)}
          //         {...provided.droppableProps}
          //       >
          //         {el.map((item, index) => (
          //           <Draggable
          //             key={item.id}
          //             draggableId={item.id}
          //             index={index}
          //           >
          //             {(provided, snapshot) => (
          //               <div
          //                 ref={provided.innerRef}
          //                 {...provided.draggableProps}
          //                 {...provided.dragHandleProps}
          //                 style={getItemStyle(
          //                   snapshot.isDragging,
          //                   provided.draggableProps.style
          //                 )}
          //               >
          //                 <div
          //                   style={{
          //                     display: "flex",
          //                     justifyContent: "space-around"
          //                   }}
          //                 >
          //                   {item.content}
          //                   <button
          //                     type="button"
          //                     onClick={() => {
          //                       const newState = [...state];
          //                       newState[ind].splice(index, 1);
          //                       setState(
          //                         newState.filter((group) => group.length)
          //                       );
          //                     }}
          //                   >
          //                     delete
          //                   </button>
          //                 </div>
          //               </div>
          //             )}
          //           </Draggable>
          //         ))}
          //         {provided.placeholder}
          //       </div>
          //     )}
          //   </Droppable>
          // ))}

  );
}