import React, { useState, useEffect } from "react";
import { useAppContext } from "./../libs/contextLib";
import NewTable from "./NewTable";
import EventTable from "./EventsTable";
import EventFlow from "./EventsFlow";
import ScanPopup from "../Containers/ScanPopup";
import crossIcon from "./../img/icon-cross.svg";
import {customCarret} from "./../libs/carret";
import {
  readDataAWS,
  getCompanyPinsAWS,
  deletePinAWS,
  changeStatePinAWS,
  checkExistenceAWS,
  insertPinDBAWS,
  getEventsAWS,
  getStatesAWS,
  getZonesAWS,
  changePlaceAWS,
  getChangePlaceSettingsAWS,
  checkPlaceOccupiedAWS
} from "./../libs/lambdaLib";
import { flattenObject } from "../libs/helperFunctions";
import {ToggleButtonGroup, ToggleButton} from "react-bootstrap";

let connectedReaderInterval;
let scanPopupInterval;
let refreshInterval;

//import socketIOClient from "socket.io-client";
let modalScanTime = 100;


let columns = [
];

let attributesAdded = false;


export default function CompanyPinList() {
  const [scanData, setScanData] = useState("");
  const [scanShow, isScanShow] = useState(false);
  const [scanPopupTime, setScanPopupTime] = useState(modalScanTime);
  const [pinList, setPinList] = useState([]);
  const {
    scanner,
    company,
    readerOpen,
    username,
    setReaderOpen,
    isFocus,
    attributes,
    UUID,
    changePlace,
    language,
    hasQRCode,
    labelInfo,
    printer
  } = useAppContext();
  const [modalTitle, setModalTitle] = useState("");
  const [modalBody, setModalBody] = useState("");
  const [modalClass, setModalClass] = useState("");
  const [isReadingData, setIsReadingData] = useState(false);
  const [showEvents, setShowEvents] = useState(false);
  const [eventList, setEventList] = useState([]);
  const [buttonStates, setButtonStates] = useState([]);
  const [defaultButtonStates, setDefaultButtonStates] = useState("");
  const [newPlace,setNewPlace] = useState("0");
  const [QRArray,setQRArray] = useState([]);
  const [zones, setZones] = useState([]);
  const [dropdownClass, setDrowndownClass] = useState("dropdown__assign");

  const [currentParking,setCurrentParking] = useState(0);
  const [newplaceFormatter,setNewplaceFormatter] = useState([
    {
    inputType: "number",
    inputMax: 60,
    inputMin: 1,
    inputFormatter: [],
    validation: [{action:"LENGTH",value: 1,text: "2 cijfers"},
    {action:"between",pos: 0,text: "Tussen 1-100 ",values:[["1","100"]]}]
      }]);
    const [placeValidationText, setPlaceValidationText] = useState([]);
    const [placeWarningText, setPlaceWarningText] = useState([]);
    const [showChangePlace,setShowChangePlace] = useState(false);
    const [canSubmitNewPlace,setCanSubmitNewPlace] = useState(true);
    const [togglePlace,setTogglePlace] = useState({});

    // const [parkingList,setParkingList] = useState(["Parkeergarage","P4","TK"]);

      // const newplaceFormatter = {
  //   inputType: "string",
  //   inputFormatter: [{pos:1,action:"ADD",substring: "."},{action:"MAX_LENGTH",value: 5}],
  //   validation: [{action:"LENGTH",value: 5,text: "4 cijfers"},
  //               {action:"starts",pos: 0,text: "1ste cijfer, 0 1 of 2",values:["0","1","2"]},
  //               {action:"between",pos: 0,text: "Tussen 0.001-0.182, 1.001-1.183 of 2.001-2.183  ",values:[["0.001","0.182"],["1.001","1.183"],["2.001","2.182"]]},
  //               ]
  //   }

  // const newplaceFormatter = {
  //   inputType: "number",
  //   inputMax: 60,
  //   inputMin: 1,
  //   inputFormatter: [],
  //   validation: [{action:"LENGTH",value: 2,text: "2 cijfers"},
  //   {action:"between",pos: 0,text: "Tussen 1-60 ",values:[["1","60"]]}
  //   ]
  //   }


  // const newplaceFormatter = {
  //   inputType: "toggleGroup",
  //   toggles: 2,
  //   inputFormatter: [],
  //  validation: [],
  //  toggleGroups: [{title:"Rij", values:[1,2,3,4,5]},{title:"Positie", values:["Begin","Midden","Eind"]}]
  //   }


  useEffect(() => {
    getAllPins();
    getStates();
    getZones();
   getChangePlaceSettings();
    return () => {
      clearInterval(refreshInterval);
    };
  }, []);

  useEffect(() => {
    getStates();
    getZones();
  }, [scanData]);

  useEffect(() => {
    if(attributes.length > 0){
      columns = [];
      attributesAdded=false;
      console.log("attributes");
      columns.push({
        dataField: "chassis",
        text: UUID,
        sortCaret: (order, column) => {
          return customCarret(order, column);
        },
        sort: true,
          });
      for (let i = 0; i < attributes.length; i++) {
        const element = attributes[i];
        console.log(`string${language}`);
        const line =   {
          dataField: element.attribute ,
          text:  element[`string${language}`],
          hidden: element.default===0?true:false,
          sortCaret: (order, column) => {
            return customCarret(order, column);
          },
          sort: true,
        }
        console.log(line.hidden);

        columns.push(line);
      }
      columns.push({
        dataField: "placeName",
        text: "Plaats",
        sort: true,
        sortCaret: (order, column) => {
          return customCarret(order, column);
        },
      })
      columns.push({
        dataField: "pinStatus",
        text: "Status",
        sort: true,
        sortCaret: (order, column) => {
          return customCarret(order, column);
        },
      })
      attributesAdded = true;
    }
  },
  [attributes])


  async function getZones() {
    let data = await getZonesAWS(company);
    const { payload } = readDataAWS(data);
    if(payload){
    setZones(zoneMapping(payload.data));
    console.log("GetZones");
    console.log(payload.data);
    }

  }

  async function getStates() {
    let data = await getStatesAWS(company);
    const { payload } = readDataAWS(data);
    setDefaultButtonStates(payload.data);
    setButtonStates(buttonMapping(payload.data));

    console.log(payload.data);
  }

  async function  getChangePlaceSettings(){
    try{
      let data = await getChangePlaceSettingsAWS(company);
      const { payload } = readDataAWS(data);
      setNewplaceFormatter(payload.data.default);
    }
    catch(e){

    }
  };



  useEffect(() => {
    if (scanData.pinStatus === "Sold" || scanData.pinStatus === "Delete") {
      setButtonStates([]);
    } else if (defaultButtonStates) {
      setButtonStates(buttonMapping(defaultButtonStates));
    }
  }, [scanData]);

  useEffect(() => {
    console.log(pinList);
  }, [pinList]);

  const getAllPins = async () => {
    let result = await getCompanyPinsAWS(company);
    result = readDataAWS(result).payload;
    for (let i = 0; i < result.length; i++) {
      result[i] = flattenObject(result[i]);

    }
    setPinList(result);
  };


  const handleEventsClose = () => {
    setShowEvents(false);
  };

  const onCloseScanPopup = () => {
    closePopup();
  };

  const buttonMapping = (array) => {
    return array.flatMap((state) => {
      let item = {
      };
      if (state.action === "CHANGE_STATE") {
        item = {
          title: state.name,
          buttonClass: state.buttonClass,
          action : state.action,
          callback: () => {
            handleStateChange(state.state,state.deletePlace);
          }
        };
      } else if (state.action === "DELETE") {
        item = {
          title: state.name,
          buttonClass: state.buttonClass,
          callback: handleDelete,
          action: state.action
        };
      }
      else if (state.action === "CHANGE_PLACE" && changePlace["withoutScan"]) {
        item = {
          title: state.name,
          buttonClass: state.buttonClass,
          disabledInBoard: !changePlace["inBoard"],
          action : state.action,
          callback: handleClickChangePlace
        };
      }  else if (state.action === "SOLD") {
        item = {
          title: state.name,
          buttonClass: state.buttonClass,
          callback: handleSold,
          action : state.action

        };
      } else if (state.action === "ARRAY") {
        item = {
          title: state.name,
          buttonClass: state.buttonClass,
          action : state.action,
          callback: async () => {
            let data = await getStatesAWS(company, state.stateArray);
            const { payload } = readDataAWS(data);
            setButtonStates(buttonMapping(payload.data));
          }
        };
      }
      else{
        return [];
      }
      return item;
    });
  };

  const zoneMapping = (array) => {
    return array.map((state) => {
      let item = {
        title: state.name,
        buttonClass: state.buttonClass,
        parkingName: state.parkingName,
      };
      if (state.action === "ZONE") {
        item.callback = () => {
          handleChangePlace(state.name,state.parkingName);
        };
      }
      return item;
    });
  };


  const handleClickChangePlace = () => {
    setShowChangePlace(true)
        closePopup();

      }

      const generateQRarray = (data) => {
        let array = [];
        for (let i = 0; i < labelInfo.length; i++) {
          const element = labelInfo[i];
          console.log(element);
          if(element.attribute){
            if(data[element.attribute]){
              if(element.action){
                if(element.action.function === "substring"){
                  array.push(data[element.attribute].substring(0,10));
                }
              }
              else{
                array.push(data[element.attribute]);
              }
            }
            else{
              array.push("-");
            }
          }
          else if(element.UUID){
            array.push(data.chassis);
          }
      }
      console.log(array);
      return array;
    }


    const handleChangePlace = async (zone,zoneParking) => {

      if(typeof zone === 'string'){
        console.log("zone change place");
        console.log(zone);
        console.log(zoneParking);

        scanData.placeName = zone;
        if(zoneParking){
          scanData.parkingName =zoneParking;
        }
      }
      else{
        console.log("placeChange");
        scanData.placeName = newPlace;
        if(newplaceFormatter[currentParking].parking){
          scanData.parkingName = newplaceFormatter[currentParking].parking;

          console.log(scanData);

        }
      }
      scanData.lastUpdate = new Date().toISOString();
      let tempPinList = [...pinList];
      tempPinList.splice(scanData.rowIndex, 1);
      if(scanData.rowIndex===0){
        setPinList([...tempPinList]);
      }

      await changePlaceAWS(scanData,scanData.placeName, username, company);
      tempPinList.unshift({ ...scanData });
      setPinList([...tempPinList]);
      scanner.feedbackSignal(2, 4);
      setShowChangePlace(false);
      setNewPlace("");
      setCurrentParking(0);
    }


  const handleSold = async () => {
    scanData.pinStatus = "Sold";
    scanData.lastUpdate = new Date().toISOString();
    let tempPinList = [...pinList];
    tempPinList.splice(scanData.rowIndex, 1);
    tempPinList.unshift({ ...scanData });
    setPinList([...tempPinList]);
    await deletePinAWS(scanData, "Sold", username, company);
    scanner.feedbackSignal(2, 4);

    closePopup();
  };

  const handleDelete = async () => {
    scanData.pinStatus = "Delete";
    scanData.lastUpdate = new Date().toISOString();
    let tempPinList = [...pinList];
    tempPinList.splice(scanData.rowIndex, 1);
    tempPinList.unshift({ ...scanData });
    setPinList([...tempPinList]);
    await deletePinAWS(scanData, "Delete", username, company);
    scanner.feedbackSignal(2, 4);
    closePopup();
  };

  const handleStateChange = async (state,deletePlace) => {
    scanData.pinStatus = state;
    scanData.placeName = deletePlace?"None":scanData.placeName ;
    scanData.lastUpdate = new Date().toISOString();
    let tempPinList = [...pinList];
    tempPinList.splice(scanData.rowIndex, 1);
    if(scanData.rowIndex===0){
      setPinList([...tempPinList]);
    }

    await changeStatePinAWS(scanData, state, company, username,deletePlace);
    tempPinList.unshift({ ...scanData });
    setPinList([...tempPinList]);
    scanner.feedbackSignal(2, 4);
    closePopup();
  };

  const onClickRow = async (data, rowIndex) => {
    console.log("Row data " + data);
    console.log(data);
    data.rowIndex = rowIndex;
    setScanData(data);
    setModalTitle("Pin Info");
    setModalBody(modalBodyScanned(data));
    timerScanPopup();
    if(hasQRCode){
      setQRArray(generateQRarray(data));

    }
    await freeScanning(data);
    //TODO: What if this is send TWICE??? (clicking twice)
    //ipRenderer.send("assignNewPin", data);
  };

  const closePopup = () => {
    isScanShow(false);
    clearInterval(scanPopupInterval);
    setScanPopupTime(modalScanTime);
    setButtonStates(buttonMapping(defaultButtonStates));
  };

  const modalBodyScanned = (data) => {
    console.log("scanData");
    console.log(data);
    let body = [];
    body.push(
    <tr>
      <td>{UUID}</td>
      <td>{data.chassis}</td>
    </tr>)
    body.push(attributes.flatMap( (element) => {
      if(element.default){
        return (
          <tr>
          <td>{element[`string${language}`]}</td>
          <td>{data[element.attribute]}</td>
        </tr>)
      }
      else{
        return [];
      }

    }))
    body.push([
      <tr>
      <td>Plaats</td>
      <td>{data.placeName}</td>
    </tr>]
      )
    return (
      <table className="_table -small">
        {body}
      </table>
    );
  };

  const onClickHistory = () => {
    getEventsPin(scanData.chassis);
    setShowEvents(true);
  };


  const getEventsPin = async (chassis) => {
    const result = await getEventsAWS(chassis, company);
    const { payload } = readDataAWS(result);
    console.log(payload);

    setEventList(payload);
  };

  const timerScanPopup = () => {
    clearInterval(scanPopupInterval);
    isScanShow(true);
    setScanPopupTime(modalScanTime);
    console.log("Scan?");
    scanPopupInterval = setInterval(() => {
      setScanPopupTime((oldValue) => {
        if (oldValue < 2) {
          closePopup();
          return modalScanTime;
        }
        return oldValue - 1;
      });
    }, 1000);
  };

  async function freeScanning(data) {
    console.log("ScanData Reassign");
    console.log(data);
    if (!scanner) {
      return;
    }
    try {
      const result = await scanner.startScanning(modalScanTime * 5);
      if (result) {
        setModalTitle("Data ophalen");
        setModalBody("");
        setIsReadingData(true);
        timerScanPopup();
        const resultExistence = await checkExistenceAWS(
          result,
          company,
          username
        );
        const { statusCode, payload, message } = readDataAWS(resultExistence);
        const payloadExistence = payload;
        console.log(statusCode);
        if (statusCode === 404) {
          setModalTitle("Geen geldige Smartpin");
          setModalBody("");
          setModalClass("danger");
          scanner.feedbackSignal(2, 2);
        } else if (statusCode == 200) {
          setModalTitle("Pin al geregistreerd");
          setModalBody("Gebruik een andere pin");
          setModalClass("warning");
          scanner.feedbackSignal(0, 1);
          return;
        } else {
          await insertPinDBAWS(data, result, username, company, true);
          setModalTitle("Nieuwe pin toegewezen");
          setModalBody(modalBodyScanned(data));
          setModalClass("success");
          scanner.feedbackSignal(2, 4);
        }
      }
    } catch (e) {
      if (e !== "" && e !== "stopped") {
        setReaderOpen(false);
        console.log(e);
      }
    }
  }


  const zoneButtonArray = () => {
    let buttons = [<></>];
    console.log("zones")

    for (let i = 0; i < zones.length; i++) {
      const element = zones[i];
      console.log(element)
      const button = (
        <span
          className={`button ${element.buttonClass}`}
          onClick={() => {element.callback(element.title)}}
        >
          {element.title}
        </span>
      );
     buttons.push(button);
    }
    console.log(buttons)
    return buttons;
  };


  useEffect( () => {
    console.log("togglePlace")
    console.log(togglePlace)
    let keys = Object.keys(togglePlace);
    const placeFormatter = newplaceFormatter[currentParking];

    if( keys.length === placeFormatter.toggles ){
      setCanSubmitNewPlace(true);
      setPlaceValidationText("");
      let stringT = "";
      for(let i = 0; i < keys.length; i++){
        if(i==0){
          stringT = stringT +   togglePlace[placeFormatter.toggleGroups[i].title];

        }
        else{
          stringT = stringT + " " +    togglePlace[placeFormatter.toggleGroups[i].title];

        }
      }
      setNewPlace(stringT);
    }
    else{
      setCanSubmitNewPlace(false);
      setPlaceValidationText("Maak een keuze voor elke waarde");
    }

  },[togglePlace])

  useEffect( () => {
    console.log("togglePlace")
    console.log(togglePlace)
    let keys = Object.keys(togglePlace);
    const placeFormatter = newplaceFormatter[currentParking];

    if( keys.length === placeFormatter.toggles ){
      setCanSubmitNewPlace(true);
      setPlaceValidationText("");
      let stringT = "";
      for(let i = 0; i < keys.length; i++){
        if(i==0){
          stringT = stringT +   togglePlace[placeFormatter.toggleGroups[i].title];

        }
        else{
          stringT = stringT + " " +    togglePlace[placeFormatter.toggleGroups[i].title];

        }
      }
      setNewPlace(stringT);
    }
    else{
      setCanSubmitNewPlace(false);
      setPlaceValidationText("Maak een keuze voor elke waarde");
    }

  },[togglePlace])

useEffect( () => {
  async function newplaceAsyncFunction() {

  const placeFormatter = newplaceFormatter[currentParking];

  for(let i=0;i<placeFormatter.inputFormatter.length;i++){
    const el = placeFormatter.inputFormatter[i];
    if(el.action === "ADD"){

      if(newPlace.length > 1 && newPlace.split(el.substring).length === 1){

        setNewPlace(newPlace.slice(0,el.pos).concat(el.substring).concat(newPlace.slice(el.pos)) )
      }
    }
    else if(el.action === "MAX_LENGTH"){
      if(newPlace.length > el.value){
        setNewPlace(newPlace.slice(0, el.value))
      }
    }

  }

  let text =[];
  if (placeFormatter.inputType === "number" && newPlace.charAt(0) === "0"){
    setNewPlace(newPlace.substring(1));
  }
  for(let i=0;i<placeFormatter.validation.length;i++){
    const el = placeFormatter.validation[i];
    let condi = false;
    switch (el.action) {
      case "LENGTH" :
        condi = newPlace.length >= el.value
        break;
      case "starts" :
        for(let j=0;j < el.values.length; j++){
          if(newPlace.startsWith(el.values[j])){
            condi=true;
          }
        }
        break;
      case "between" :
        for(let j=0;j < el.values.length; j++){
          let cmp = el.values[j];
          if(placeFormatter.inputType === "string" && newPlace >= cmp[0] && newPlace <= cmp[1]){
            condi=true;
          }
          else if(placeFormatter.inputType === "number" && newPlace >= Number(cmp[0]) && newPlace <= Number(cmp[1])){
            condi=true;
          }
        }
        break;
      default:
        break;
    }
    if(!condi){
    text.push(<><p>{el.text}</p></>);
    }
  }


  let parkingName;
  if(newplaceFormatter[currentParking].parking){
    parkingName = newplaceFormatter[currentParking].parking;

    console.log(scanData);

  }
  let data = await checkPlaceOccupiedAWS(company,newPlace,parkingName);
  const { payload } = readDataAWS(data);
  if(payload  && payload.length && payload.length > 0){
    setPlaceWarningText(<><p>Plaats bezet door andere wagen</p></>);

  }
  else{
    setPlaceWarningText("");
  }

  if(placeFormatter.inputType !== "toggleGroup"){
    setCanSubmitNewPlace(text.length===0);
    setPlaceValidationText(text);
  }
}
 newplaceAsyncFunction();
},[newPlace])

const getChangePlaceForm = () => {
  const placeFormatter = newplaceFormatter[currentParking];
  let formBuilder = [];

  if(placeFormatter.inputType === undefined){
    return <></>
  }
  else if (placeFormatter.inputType === "number" || placeFormatter.inputType === "string"){
    formBuilder.push(<input type={placeFormatter.inputType} min={placeFormatter.inputMin}  max={placeFormatter.inputMax} value={newPlace} onChange={(e) => {setNewPlace(e.target.value)}} placeholder="1.183"/>)
    if(placeFormatter.zones){
      formBuilder.push( <h3 class="model__title">Zones</h3>)
      let toggles = []
      for (let j = 0; j < placeFormatter.zones.length; j++) {
        toggles.push(<ToggleButton className={"button"} value={placeFormatter.zones[j]}>{placeFormatter.zones[j]}</ToggleButton>)
       }
       formBuilder.push(  <ToggleButtonGroup    value={newPlace} name={"zones"}  type="radio" size="lg" onChange={e => {
        console.log(e);
        let tog = {...togglePlace}
        handleChangePlace(e,newplaceFormatter[currentParking].parking);

        }}>
        {toggles}
      </ToggleButtonGroup>)
        formBuilder.push( <br />);
    }
  return <>{formBuilder}
 </>
}
else if(placeFormatter.inputType === "toggleGroup"){
  let formBuilder = [];
  for(let i=0; i < placeFormatter.toggles; i++){
    let el =  placeFormatter.toggleGroups[i];
    formBuilder.push( <h3 class="model__title">{el.title}</h3>)
    let toggles = [];
    for (let j = 0; j < el.values.length; j++) {
     toggles.push(<ToggleButton className={"button"} value={el.values[j]}>{el.values[j]}</ToggleButton>)
    }
    formBuilder.push(  <ToggleButtonGroup    value={togglePlace[el.title]} name={el.title} type="radio" size="lg" onChange={e => {
      console.log(e);
      let tog = {...togglePlace}
      tog[el.title] = e;
      setTogglePlace(tog);
      }}>
      {toggles}
    </ToggleButtonGroup>)
      formBuilder.push( <br />);
  }
  return formBuilder;

}
}


const onClickUserDropdown = () => {
  if (dropdownClass === "dropdown__assign") setDrowndownClass("dropdown__assign -open");
  else setDrowndownClass("dropdown__assign");
};

const onClickUserSelector = (e) => {
  console.log(e.target.value);
  setDrowndownClass("dropdown__assign");
  setCurrentParking(e.target.value);
};

  return (
    <>

      <div class={`model ${showChangePlace ? "-active" : ""}`} data-model="status">
        <div class="model__container">
         { newplaceFormatter.length > 1 && <><h1>Parking</h1>
          <div
      className={dropdownClass}
      role="group"
      onClick={onClickUserDropdown }
      onChange={(e) => onClickUserSelector(e)}
    >
      {newplaceFormatter[currentParking].parking}
      <div className="dropdown__list__assign">
        {newplaceFormatter.map((parking,i) => {
          console.log("Dropdown" + i +"and" + currentParking)
          const isSelect = `${i}` === `${currentParking}`;
          console.log(isSelect)

          return (
            <div className="dropdown__option__assign">
              <input
                name="max"
                type="radio"
                value={i}
                id={i}
                checked={isSelect}
              />
              <label for={i}>{parking.parking}</label>
            </div>
          );
        })}
      </div>
      </div></>
}
          <h2 class="model__title">Voer Parkeerplaats in</h2>
          <div class={`form__control ${false ? "-error" : ""}`}>
            <label for="Plaats">Plaats</label>

            {getChangePlaceForm()}
            <span style={{ color: "red" }}>{placeValidationText}</span>
            <span style={{ color: "orange" }}>{placeWarningText}</span>
          </div>
          <span
          class={`button -open-model ${canSubmitNewPlace?"":"-disabled"}`}
          onClick={handleChangePlace}
        >Wijzig</span>        { zoneButtonArray()}

                 <img
            class="model__cross"
            src={crossIcon}
            onClick={() => setShowChangePlace(false)}
          />
        </div>
      </div>
      <div
        div
        className={`model -large ${showEvents ? "-active" : ""}`}
        data-model="history"
      >
        <div className="model__container">
          <h1>Historie {scanData.chassis}</h1>
          {/* <EventTable eventList={eventList} pin={scanData.chassis} /> */}
          <EventFlow eventList={eventList} pin={scanData.chassis} />

          <img
            src={crossIcon}
            alt=""
            className="model__cross"
            onClick={handleEventsClose}
          />
        </div>
      </div>

      <ScanPopup
        title={modalTitle}
        body={modalBody}
        className={modalClass}
        pinStatus={scanData.pinStatus}
        pinID={scanData.pinID}
        popupTime={scanPopupTime}
        show={scanShow}
        handleClose={onCloseScanPopup}
        buttons={buttonStates}
        history={true}
        isReading={isReadingData}
        onClickHistory={onClickHistory}
        QRArray={QRArray}
        hasQRCode={hasQRCode}
        printer={printer}
      />
      <section>
        { attributesAdded && <NewTable onClickRow={onClickRow} pinList={pinList} tableColumns={columns} />}
      </section>
    </>
  );
}
