import React from 'react';

export const customCarret = (order, column) => {
  if (!order)
    return (
      <>
        <span className="-carret" />
        <span className="-carret -open" />
      </>
    );
  else if (order === "asc") return <span className="-carret" />;
  else if (order === "desc") return <span className="-carret -open" />;
  return null;
};