import React, { useState, useEffect } from "react";
import { useAppContext } from "./../libs/contextLib";
import NewTable from "./NewTable";
import EventTable from "./EventsTable";
import EventFlow from "./EventsFlow";
import ScanPopup from "../Containers/ScanPopup";
import crossIcon from "./../img/icon-cross.svg";
import {customCarret} from "./../libs/carret";
import {
    readDataAWS,
    getCompanyPinsAWS,
    deletePinAWS,
    changeStatePinAWS,
    checkExistenceAWS,
    insertPinDBAWS,
    getEventsAWS,
    getStatesAWS,
    getZonesAWS,
    getDurationAWS,
    changePlaceAWS} from "./../libs/lambdaLib";
import { flattenObject } from "../libs/helperFunctions";
let modalScanTime = 100;

let scanPopupInterval;
let refreshInterval;

//import socketIOClient from "socket.io-client";


let columns = [
];

let attributesAdded = false;


export default function Reports() {
  const [scanData, setScanData] = useState("");
  const [scanShow, isScanShow] = useState(false);
  const [scanPopupTime, setScanPopupTime] = useState(modalScanTime);
  const [pinList, setPinList] = useState([]);
  const {
    scanner,
    company,
    readerOpen,
    username,
    setReaderOpen,
    isFocus,
    attributes,
    UUID,
    changePlace,
    language,
    hasQRCode,
    labelInfo,
    printer
  } = useAppContext();
  const [modalTitle, setModalTitle] = useState("");
  const [modalBody, setModalBody] = useState("");
  const [modalClass, setModalClass] = useState("");
  const [isReadingData, setIsReadingData] = useState(false);
  const [showEvents, setShowEvents] = useState(false);
  const [eventList, setEventList] = useState([]);
  const [buttonStates, setButtonStates] = useState([]);
  const [defaultButtonStates, setDefaultButtonStates] = useState("");
  const [newPlace,setNewPlace] = useState("0");
  const [showChangePlace,setShowChangePlace] = useState(false);
  const [QRArray,setQRArray] = useState([]);
  const [zones, setZones] = useState([]);
  const [zoneButtons, setZoneButtons] = useState([]);
  const [chartData, setChartData] = useState([]);

  useEffect(() => {
    getDurationPins();

    return () => {
      clearInterval(refreshInterval);
    };
  }, []);

  useEffect(() => {
    if(attributes.length > 0){
      columns = [];
      attributesAdded=false;
      console.log("attributes");
      columns.push({
        dataField: "chassis",
        text: UUID,
        sortCaret: (order, column) => {
          return customCarret(order, column);
        },
        sort: true,
          });
      for (let i = 0; i < attributes.length; i++) {
        const element = attributes[i];
        console.log(`string${language}`);
        const line =   {
          dataField: element.attribute ,
          text:  element[`string${language}`],
          hidden: element.default===0?true:false,
          sortCaret: (order, column) => {
            return customCarret(order, column);
          },
          sort: true,
        }
        console.log(line.hidden);

        columns.push(line);
      }
      columns.push({
        dataField: "placeName",
        text: "Plaats",
        sort: true,
        sortCaret: (order, column) => {
          return customCarret(order, column);
        },
      })
      columns.push({
        dataField: "pinStatus",
        text: "Status",
        sort: true,
        sortCaret: (order, column) => {
          return customCarret(order, column);
        },
      })
      columns.push({
        dataField: "firstEvent",
        text: "Doorlooptijd",
        sort: true,
        sortCaret: (order, column) => {
          return customCarret(order, column);
        },
      })
      attributesAdded = true;
    }
  },
  [attributes])



  const getDurationPins = async () => {
    let result = await getDurationAWS(company);
    result = readDataAWS(result).payload;
    let interval
    for (let i = 0; i < result.length; i++) {
      result[i] = flattenObject(result[i]);
    }
    setPinList(result);
  };


  const handleEventsClose = () => {
    setShowEvents(false);
  };

  const onCloseScanPopup = () => {
    closePopup();
  };

  const buttonMapping = (array) => {
    return array.flatMap((state) => {
      let item = {
      };
      if (state.action === "CHANGE_STATE") {
        item = {
          title: state.name,
          buttonClass: state.buttonClass,
          action : state.action,
          callback: () => {
            handleStateChange(state.state);
          }
        };
      } else if (state.action === "DELETE") {
        item = {
          title: state.name,
          buttonClass: state.buttonClass,
          callback: handleDelete,
          action: state.action
        };
      }
      else if (state.action === "CHANGE_PLACE" && changePlace["withoutScan"]) {
        item = {
          title: state.name,
          buttonClass: state.buttonClass,
          disabledInBoard: !changePlace["inBoard"],
          action : state.action,
          callback: handleClickChangePlace
        };
      }  else if (state.action === "SOLD") {
        item = {
          title: state.name,
          buttonClass: state.buttonClass,
          callback: handleSold,
          action : state.action

        };
      } else if (state.action === "ARRAY") {
        item = {
          title: state.name,
          buttonClass: state.buttonClass,
          action : state.action,
          callback: async () => {
            let data = await getStatesAWS(company, state.stateArray);
            const { payload } = readDataAWS(data);
            //setButtonStates(buttonMapping(payload.data));
          }
        };
      }
      else{
        return [];
      }
      return item;
    });
  };

  const zoneMapping = (array) => {
    return array.map((state) => {
      let item = {
        title: state.name,
        buttonClass: state.buttonClass,
      };
      if (state.action === "ZONE") {
        item.callback = () => {
          handleChangePlace(state.name);
        };
      }
      return item;
    });
  };
  

  const handleClickChangePlace = () => {
    setShowChangePlace(true)
        closePopup();

      }

      const generateQRarray = (data) => {
        let array = [];
        for (let i = 0; i < labelInfo.length; i++) {
          const element = labelInfo[i];
          console.log(element);
          if(element.attribute){
            if(data[element.attribute]){
              if(element.action){
                if(element.action.function === "substring"){
                  array.push(data[element.attribute].substring(0,10));
                }
              }
              else{
                array.push(data[element.attribute]);
              }
            }
            else{
              array.push("-");
            }
          }
          else if(element.UUID){
            array.push(data.chassis);
          }
      }
      console.log(array);
      return array;
    }


    const handleChangePlace = async (zone) => {
      console.log("zone change place");
      console.log(zone);
      if(zone !== undefined){
        scanData.placeName = zone;
      }
      else{
        scanData.placeName = newPlace;
      }
      scanData.lastUpdate = new Date().toISOString();
      let tempPinList = [...pinList];
      tempPinList.splice(scanData.rowIndex, 1);
      if(scanData.rowIndex===0){
        setPinList([...tempPinList]);
      }
  
      await changePlaceAWS(scanData,scanData.placeName, username, company);
      tempPinList.unshift({ ...scanData });
      setPinList([...tempPinList]);
      scanner.feedbackSignal(2, 4);
      setShowChangePlace(false);
    }


  const handleSold = async () => {
    scanData.pinStatus = "Sold";
    scanData.lastUpdate = new Date().toISOString();
    let tempPinList = [...pinList];
    tempPinList.splice(scanData.rowIndex, 1);
    tempPinList.unshift({ ...scanData });
    setPinList([...tempPinList]);
    await deletePinAWS(scanData, "Sold", username, company);
    scanner.feedbackSignal(2, 4);

    closePopup();
  };

  const handleDelete = async () => {
    scanData.pinStatus = "Delete";
    scanData.lastUpdate = new Date().toISOString();
    let tempPinList = [...pinList];
    tempPinList.splice(scanData.rowIndex, 1);
    tempPinList.unshift({ ...scanData });
    setPinList([...tempPinList]);
    await deletePinAWS(scanData, "Delete", username, company);
    scanner.feedbackSignal(2, 4);
    closePopup();
  };

  const handleStateChange = async (state) => {
    scanData.pinStatus = state;
    scanData.lastUpdate = new Date().toISOString();
    let tempPinList = [...pinList];
    tempPinList.splice(scanData.rowIndex, 1);
    if(scanData.rowIndex===0){
      setPinList([...tempPinList]);
    }

    await changeStatePinAWS(scanData, state, company, username);
    tempPinList.unshift({ ...scanData });
    setPinList([...tempPinList]);
    scanner.feedbackSignal(2, 4);
    closePopup();
  };

  const onClickRow = async (data, rowIndex) => {
    console.log("Row data " + data);
    data.rowIndex = rowIndex;
    setScanData(data);
    setModalTitle("Pin Info");
    setModalBody(modalBodyScanned(data));
    timerScanPopup();
    if(hasQRCode){
      setQRArray(generateQRarray(data));

    }
    await freeScanning();
    //TODO: What if this is send TWICE??? (clicking twice)
    //ipRenderer.send("assignNewPin", data);
  };

  const closePopup = () => {
    isScanShow(false);
    clearInterval(scanPopupInterval);
    setScanPopupTime(modalScanTime);
    //setButtonStates(buttonMapping(defaultButtonStates));
  };

  const modalBodyScanned = (data) => {
    console.log("scanData");
    console.log(data);
    let body = [];
    body.push(
    <tr>
      <td>{UUID}</td>
      <td>{data.chassis}</td>
    </tr>)
    body.push(attributes.flatMap( (element) => {
      if(element.default){
        return (
          <tr>
          <td>{element[`string${language}`]}</td>
          <td>{data[element.attribute]}</td>
        </tr>)
      }
      else{
        return [];
      }

    }))
    body.push([
      <tr>
      <td>Plaats</td>
      <td>{data.placeName}</td>
    </tr>]
      )
    return (
      <table className="table -small">
        {body}
      </table>
    );
  };

  const onClickHistory = () => {
    getEventsPin(scanData.chassis);
    setShowEvents(true);
  };


  const getEventsPin = async (chassis) => {
    const result = await getEventsAWS(chassis, company);
    const { payload } = readDataAWS(result);
    console.log(payload);

    setEventList(payload);
  };

  const timerScanPopup = () => {
    clearInterval(scanPopupInterval);
    isScanShow(true);
    setScanPopupTime(modalScanTime);
    console.log("Scan?");
    scanPopupInterval = setInterval(() => {
      setScanPopupTime((oldValue) => {
        if (oldValue < 2) {
          closePopup();
          return modalScanTime;
        }
        return oldValue - 1;
      });
    }, 1000);
  };

  async function freeScanning() {
    console.log(scanData);
    if (!scanner) {
      return;
    }
    try {
      const result = await scanner.startScanning(modalScanTime * 5);
      if (result) {
        setModalTitle("Data ophalen");
        setModalBody("");
        setIsReadingData(true);
        timerScanPopup();
        const resultExistence = await checkExistenceAWS(
          result,
          company,
          username
        );
        const { statusCode, payload, message } = readDataAWS(resultExistence);
        const payloadExistence = payload;
        console.log(statusCode);
        if (statusCode === 404) {
          setModalTitle("Geen geldige Smartpin");
          setModalBody("");
          setModalClass("danger");
          scanner.feedbackSignal(2, 2);
        } else if (statusCode == 200) {
          setModalTitle("Pin al geregistreerd");
          setModalBody("Gebruik een andere pin");
          setModalClass("warning");
          scanner.feedbackSignal(0, 1);
          return;
        } else {
          await insertPinDBAWS(scanData, result, username, company, true);
          setModalTitle("Nieuwe pin toegewezen");
          setModalBody(modalBodyScanned(scanData));
          setModalClass("success");
          scanner.feedbackSignal(2, 4);
        }
      }
    } catch (e) {
      if (e !== "" && e !== "stopped") {
        setReaderOpen(false);
        console.log(e);
      }
    }
  }

  
  const zoneButtonArray = () => {
    let buttons = [<></>];
    console.log("zones")
    for (let i = 0; i < zones.length; i++) {
      const element = zones[i];
      const button = (
        <span
          className={`button ${element.buttonClass}`}
          onClick={element.callback}
        >
          {element.title}
        </span>
      );
     buttons.push(button);
    }
    console.log(buttons)
    return buttons;
  };


  return (
    <>
            <div class={`model ${showChangePlace ? "-active" : ""}`} data-model="status">
        <div class="model__container">
          <h2 class="model__title">Voer plaats in</h2>
          <div class={`form__control ${false ? "-error" : ""}`}>
            <label for="Plaats">Plaats</label>
            <input type="number" onChange={(e) => {setNewPlace(e.target.value)}} />
          </div>
          <span
          class="button -open-model"
          onClick={handleChangePlace}
        >Wijzig</span>
        { zoneButtonArray()}
                 <img
            class="model__cross"
            src={crossIcon}
            onClick={() => setShowChangePlace(false)}
          />
        </div>
      </div>
      <div
        div
        className={`model -large ${showEvents ? "-active" : ""}`}
        data-model="history"
      >
        <div className="model__container">
          <h1>Historie {scanData.chassis}</h1>
          {/* <EventTable eventList={eventList} pin={scanData.chassis} /> */}
          <EventFlow eventList={eventList} pin={scanData.chassis} />

          <img
            src={crossIcon}
            alt=""
            className="model__cross"
            onClick={handleEventsClose}
          />
        </div>
      </div>

      <ScanPopup
        title={modalTitle}
        body={modalBody}
        className={modalClass}
        pinStatus={scanData.pinStatus}
        pinID={scanData.pinID}
        popupTime={scanPopupTime}
        show={scanShow}
        handleClose={onCloseScanPopup}
        buttons={buttonStates}
        history={true}
        isReading={isReadingData}
        onClickHistory={onClickHistory}
        QRArray={QRArray}
        hasQRCode={hasQRCode}
        printer={printer}
      />
      <section>
        { attributesAdded && <NewTable onClickRow={onClickRow} pinList={pinList} tableColumns={columns} csv={true}/>}
      </section>
    </>
  );
}
