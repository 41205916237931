import React, { useState, useEffect } from "react";
import { useAppContext } from "./../libs/contextLib";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import { Form, Button, Modal } from "react-bootstrap";
import addIcon from "./../img/icon-add.svg";
import arrowLeft from "./../img/arrow-left.svg";
import arrowLeftDouble from "./../img/arrow-left-double.svg";
import arrowRight from "./../img/arrow-right.svg";
import arrowRightDouble from "./../img/arrow-right-double.svg";
import crossIcon from "./../img/icon-cross.svg";

import {
  readDataAWS,
  listUsersAWS,
  addAdminUserAWS,
  removeAdminUserAWS,
  addViewUserAWS,
  addNormalUserAWS,
  createUserAWS,
} from "./../libs/lambdaLib";
import paginationFactory from "react-bootstrap-table2-paginator";
import cellEditFactory, { Type } from "react-bootstrap-table2-editor";

const customCarret = (order, column) => {
  if (!order)
    return (
      <>
        <span className="-carret" />
        <span className="-carret -open" />
      </>
    );
  else if (order === "asc") return <span className="-carret" />;
  else if (order === "desc") return <span className="-carret -open" />;
  return null;
};

let columns = [
  {
    dataField: "sk",
    text: "sk",
    sort: true,
    sortCaret: (order, column) => {
      return customCarret(order, column);
    },
    hidden: true,
    editable: false,
  },
  {
    dataField: "email",
    text: "Email",
    sort: true,
    sortCaret: (order, column) => {
      return customCarret(order, column);
    },
    editable: false,
  },
  {
    dataField: "group",
    text: "Group",
    editor: {
      type: Type.SELECT,
      options: [
        {
          value: "users",
          label: "gebruiker",
        },
        {
          value: "admins",
          label: "admin",
        },
        {
          value: "viewers",
          label: "lezen",
        },
      ],
    },
  },
  {
    dataField: "accountStatus",
    text: "Status",
    sort: true,
    sortCaret: (order, column) => {
      return customCarret(order, column);
    },
    editable: false,
  },
];

const MySearch = (props) => {
  const handleChange = (input) => {
    console.log(input.target.value);
    props.onSearch(input.target.value);
  };
  return (
    <input
      type="text"
      className="search"
      placeholder="Zoeken"
      onChange={handleChange}
    />
  );
};

const rowStyle2 = (row, rowIndex) => {
  const style = {};
  if (row.pinStatus === "Sold" || row.pinStatus === "Delete") {
    style.backgroundColor = "#d9534f";
  }

  return style;
};

export default function UsersTable() {
  const [userList, setUserList] = useState([]);
  const [showAddUserModal, setShowAddUserModal] = useState(false);
  const [newEmail, setNewEmail] = useState("");
  const [isValidEmail, setIsValidEmail] = useState(true);
  const { company, username } = useAppContext();
  const [dropdownClass, setDrowndownClass] = useState("dropdown");
  const [currentPage, setCurrentPage] = useState(1);

  const onClickPageSelectorDropdown = () => {
    if (dropdownClass === "dropdown") setDrowndownClass("dropdown -open");
    else setDrowndownClass("dropdown");
  };

  const onClickPageSelectorSize = (e, onSizePerPageChange) => {
    console.log(e.target.value);
    setDrowndownClass("dropdown");
    onSizePerPageChange(e.target.value);
  };
  const sizePerPageRenderer = ({
    options,
    currSizePerPage,
    onSizePerPageChange,
  }) => (
    <div
      className={dropdownClass}
      role="group"
      onClick={onClickPageSelectorDropdown}
      onChange={(e) => onClickPageSelectorSize(e, onSizePerPageChange)}
    >
      <div className="dropdown__list">
        {options.map((option) => {
          const isSelect = currSizePerPage === `${option.page}`;
          return (
            <div className="dropdown__option">
              <input
                name="max"
                type="radio"
                value={option.text}
                id={option.text}
                checked={isSelect}
              />
              <label for={option.text}>{option.text}</label>
            </div>
          );
        })}
      </div>
    </div>
  );

  const pageListRenderer = ({ pages, onPageChange }) => {
    const pageWithoutIndication = pages.filter(
      (p) => typeof p.page !== "string"
    );

    return (
      <div className="pagination">
        <span className="pagination__item">
          <img src={arrowLeftDouble} alt="" onClick={() => onPageChange(1)} />
        </span>
        <span
          className="pagination__item"
          onClick={() => onPageChange(currentPage === 1 ? 1 : currentPage - 1)}
        >
          <img src={arrowLeft} alt="" />
        </span>
        {pageWithoutIndication.map((p) => (
          <span
            className="pagination__item"
            onClick={() => onPageChange(p.page)}
          >
            {p.page}
          </span>
        ))}
        <span
          className="pagination__item"
          onClick={() =>
            onPageChange(
              currentPage === pages.length ? pages.length : currentPage + 1
            )
          }
        >
          <img src={arrowRight} alt="" />
        </span>
        <span className="pagination__item">
          <img
            src={arrowRightDouble}
            alt=""
            onClick={() => onPageChange(pages.length)}
          />
        </span>
      </div>
    );
  };

  useEffect(() => {
    getUsers();
  }, []);

  useEffect(() => {
    if (newEmail.length > 0) {
      validateEmail(newEmail);
    }
  }, [newEmail]);

  const validateEmail = (email) => {
    const pattern =new RegExp( /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/);
    setIsValidEmail(pattern.test(email));
  };

  const getUsers = async () => {
    const result = await listUsersAWS(company);
    setUserList(readDataAWS(result).payload);
  };

  const cellEdit = async (oldValue, newValue, row, column) => {
    if (newValue === "admins") {
      await addAdminUserAWS(row.email);
    } else if (newValue === "users") {
      await addNormalUserAWS(row.email);
    }
    else if (newValue === "viewers") {
      await addViewUserAWS(row.email);
    }
  };

  const addUser = async () => {
    setShowAddUserModal(false);
    try {
      await createUserAWS(newEmail, company);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <>
      <div
        class={`model ${showAddUserModal ? "-active" : ""}`}
        data-model="add-user"
      >
        <div class="model__container">
          <h1>Gebruiker toevoegen</h1>
          <div class={`form__control ${!isValidEmail ? "-error" : ""}`}>
            <label for="email">E-mail</label>
            <input type="text" onChange={(e) => setNewEmail(e.target.value)} />
          </div>
          <p class="form__error" hidden={isValidEmail}>
            Ongeldige email
          </p>
          <button
            class={`button -wide ${!isValidEmail ? "-disabled" : ""}`}
            onClick={addUser}
            disabled={!isValidEmail}
          >
            Toevoegen
          </button>
          <img
            src={crossIcon}
            alt=""
            class="model__cross"
            onClick={() => {
              setShowAddUserModal(false);
            }}
          />
        </div>
      </div>
      <section>
        <ToolkitProvider
          keyField="email"
          data={userList}
          columns={columns}
          search
        >
          {(tableProps) => (
            <>
              <div class="container">
                <span
                  data-model="add-user"
                  class="button -open-model"
                  onClick={() => setShowAddUserModal(true)}
                >
                  <img src={addIcon} />
                  Gebruiker toevoegen
                </span>
              </div>
              <MySearch {...tableProps.searchProps} />
              <BootstrapTable
                bootstrap4={true}
                keyField="email"
                classes="_table"
                hover
                rowStyle={rowStyle2}
                {...tableProps.baseProps}
                cellEdit={cellEditFactory({
                  mode: "click",
                  blurToSave: true,
                  afterSaveCell: cellEdit,
                  nonEditableRows: () => [username],
                })}
                pagination={paginationFactory({
                  sizePerPageRenderer,
                  pageListRenderer,
                  onPageChange: (page, sizePerPage) => {
                    setCurrentPage(page);
                  },
                })}
              />
            </>
          )}
        </ToolkitProvider>
      </section>
    </>
  );
}
