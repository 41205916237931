import React, { useState, useEffect } from "react";
//import "./App.css";
import EventTable from "./Components/EventsTable";

import NewTable from "./Components/NewTable";
import { useAppContext } from "./libs/contextLib";

import ScanPopup from "./Containers/ScanPopup";
import crossIcon from "./img/icon-cross.svg";
import {customCarret} from "./libs/carret";
import { flattenObject } from "./libs/helperFunctions";

import {
  checkExistenceAWS,
  readDataAWS,
  deletePinAWS,
  changeStatePinAWS,
  getEventsAWS,
  getStatesAWS,
  getZonesAWS,
  changePlaceAWS
} from "./libs/lambdaLib";

let scanPopupInterval;
let freeRescanInterval;

//import socketIOClient from "socket.io-client";
let modalScanTime = 19;

export const states = {
  FREESCAN: 1,
  NEWASSIGN: 2,
  OLDASSIGN: 3,
  EXISTINGPIN: 4,
  PINASSIGNED: 5,
  INVALIDPIN: 6,
  STOPPED: 7,
  UNREGISTEREDPIN: 8,
  READINGDATA: 9,
  ERROR: 10,
};

let columns = [
];

const QRcode = [
  "attributes.brand",
  "attributes.model",
  "UUID",
  "attributes.REF.",
  {value: "attributes.registration",
  transform: (attr) => {
    return attr.substring(0,10)
  }},
  "attributes.color",
  "attributes.km"
]


let attributesAdded = false;

export default function Home() {
  const [scanData, setScanData] = useState("");
  const [invalidPin, setInvalidPin] = useState(true);
  const [scanShow, isScanShow] = useState(false);
  const [scanPopupTime, setScanPopupTime] = useState(modalScanTime);
  const [pinList, setPinList] = useState([]);
  const [rowData, setRowData] = useState({});
  const [showEvents, setShowEvents] = useState(false);
  const [eventList, setEventList] = useState([]);
  const [newPlace, setNewPlace] = useState("0");
  const [showChangePlace,setShowChangePlace] = useState(false);
  const [buttonStates, setButtonStates] = useState([]);
  const [zones, setZones] = useState([]);
  const [zoneButtons, setZoneButtons] = useState([]);
  const [defaultButtonStates, setDefaultButtonStates] = useState("");
  const [QRArray,setQRArray] = useState([]);

  const {
    scanner,
    company,
    readerOpen,
    username,
    setReaderOpen,
    isFocus,
    attributes,
    UUID,
    language,
    hasQRCode,
    labelInfo,
    printer
  } = useAppContext();
  const [modalTitle, setModalTitle] = useState("");
  const [modalBody, setModalBody] = useState("");
  const [modalClass, setModalClass] = useState("none");

  const [isReadingData, setIsReadingData] = useState(false);

  useEffect(() => {
    getStates();
    getZones();
    return () => {
      console.log("Going to close");
      clearTimeout(freeRescanInterval);
      if (scanner) scanner.stopScanning();
    };
  }, []);

  useEffect(() => {
    getStates();
    getZones();
  }, [scanData]);


  useEffect(() => {
    if(attributes.length > 0 ){
      attributesAdded =false;
      columns = [];
      console.log("attributes");
      columns.push({
        dataField: "chassis",
        text: UUID,
        sortCaret: (order, column) => {
          return customCarret(order, column);
        },
        sort: true,
          });
      for (let i = 0; i < attributes.length; i++) {
        const element = attributes[i];
        const line =   {
          dataField: element.attribute ,
          text:  element[`string${language}`],
          hidden: element.default===0?true:false,
          sortCaret: (order, column) => {
            return customCarret(order, column);
          },
          sort: true,
        }
        console.log(line.hidden);

        columns.push(line);
      }
      columns.push(
        {
          dataField: "placeName",
          text: "Plaats",
          sort: true,
          sortCaret: (order, column) => {
            return customCarret(order, column);
          },
        },
        {
          dataField: "pinStatus",
          text: "Status",
          sort: true,
          sortCaret: (order, column) => {
            return customCarret(order, column);
          },
        },
        {
          dataField: "scannedTime",
          text: "Gescand op",
          sortCaret: (order, column) => {
            return customCarret(order, column);
          },
        },)
      attributesAdded = true;
    }
  },
  [attributes])

  const generateQRarray = (data) => {
    let array = [];
    for (let i = 0; i < labelInfo.length; i++) {
      const element = labelInfo[i];
      console.log(element);
      if(element.attribute){
        if(data[element.attribute]){
          if(element.action){
            if(element.action.function === "substring"){
              array.push(data[element.attribute].substring(0,10));
            }
          }
          else{
            array.push(data[element.attribute]);
          }
        }
        else{
          array.push("-");
        }
      }
      else if(element.UUID){
        array.push(data.chassis);
      }
  }
  console.log(array);
  return array;
}

  async function getStates() {
    let data = await getStatesAWS(company);
    const { payload } = readDataAWS(data);
    setDefaultButtonStates(payload.data);
    setButtonStates(buttonMapping(payload.data));

    console.log(payload.data);

  }

  async function getZones() {
    let data = await getZonesAWS(company);
    const { payload } = readDataAWS(data);
    if(payload){
      setZones(zoneMapping(payload.data));
      console.log("GetZones");
      console.log(payload.data);
      }

  }


  useEffect(() => {
    console.log("isFocusReaderopen" + readerOpen);

    if (isFocus) {
      if (readerOpen) {
        freeScanning();
      }
    } else {
      closePopup();
      if (readerOpen) {
        scanner.stopScanning();
      }
    }
  }, [isFocus]);

  useEffect(() => {
    console.log("Readeropen" + readerOpen);

    if (readerOpen) {
      freeScanning();
    }
    return () => {
      clearTimeout(freeRescanInterval);
    };
  }, [readerOpen]);

  useEffect(() => {
    if (scanData.pinStatus === "Sold" || scanData.pinStatus === "Delete") {
      setButtonStates([]);
    } else if (defaultButtonStates) {
      setButtonStates(buttonMapping(defaultButtonStates));
    }
    if(hasQRCode){
      setQRArray(generateQRarray(scanData));

    }  }, [scanData]);

  async function freeScanning() {
    try {
      const result = await scanner.startScanning(-1);
      setInvalidPin(true);
      const now = new Date();
      console.log("New scanned " + result);
      if (result) {
        setModalTitle("Data ophalen");
        setModalBody("");
        setIsReadingData(true);
        timerScanPopup();
        const resultExistence = await checkExistenceAWS(
          result,
          company,
          username
        );
        const { statusCode, payload, message } = readDataAWS(resultExistence);
        if (statusCode === 404) {
          setModalTitle("Geen geldige Smartpin");
          setModalBody("");
          setModalClass("danger");
          setButtonStates([]);
          scanner.feedbackSignal(2, 2);
        } else if (statusCode === 406) {
          setModalTitle("Pin niet geregistreerd");
          setModalBody("Wijs pin toe aan wagen");
          setButtonStates([]);
          setModalClass("warning");
          scanner.feedbackSignal(0, 1);
        } else {
          setScanData(flattenObject(payload));
          setIsReadingData(false);
          setInvalidPin(false);
          setModalTitle("Pin gescand");
          setModalBody(modalBodyScanned(flattenObject(payload)));
          payload.scannedTime = `${now.getHours()}:${now.getMinutes()}:${now.getSeconds()}`;
          console.log("pint list");
          console.log(pinList);
          setPinList((prevList) => {
            console.log(pinList);
             return [flattenObject(payload), ...prevList];
          });
        }

        timerScanPopup();
        if (isFocus) {
          setTimeout(async () => {
            await freeScanning();
          }, 2000);
        }
      }
    } catch (e) {
      if (e !== "" && e !== "stopped") {
        setReaderOpen(false);
        console.log(e);
      }
    }
  }

  const modalBodyScanned = (data) => {
    let body = [];
    body.push(
    <tr>
      <td>{UUID}</td>
      <td>{data.chassis}</td>
    </tr>)
    body.push(attributes.flatMap( (element) => {
      if(element.default){
        return (
          <tr>
          <td>{element[`string${language}`]}</td>
          <td>{data[element.attribute]}</td>
        </tr>)
      }
      else{
        return [];
      }
    }));
   body.push([
      <tr>
      <td>Plaats</td>
      <td>{data.placeName}</td>
    </tr>]
      )
    body.push([
      <tr>
      <td>Vrge plaats</td>
      <td>{data.lastPlace}</td>
    </tr>]
      )
    return (
      <table className="_table -small">
        {body}
      </table>
    );
  };

  const onCloseScanPopup = () => {
    closePopup();
  };

  const handleEventsClose = () => {
    setShowEvents(false);
  };

  const handleSold = async () => {
    await deletePinAWS(scanData, "Sold", username, company);
    scanner.feedbackSignal(2, 4);
    closePopup();
  };

  const handleDelete = async () => {
    await deletePinAWS(scanData, "Delete", username, company);
    scanner.feedbackSignal(2, 4);
    closePopup();
  };



  const handleChangePlace = async (zone) => {
    if( (typeof zone === 'string' || zone instanceof String) && zone !== undefined){
      console.log("wtf is zone");
      scanData.placeName = zone;
    }
    else{
      console.log("wtf is nonzone");
      scanData.placeName = newPlace;
    }
    scanData.lastUpdate = new Date().toISOString();
    let tempPinList = [...pinList];
    tempPinList.splice(scanData.rowIndex, 1);
    if(scanData.rowIndex===0){
      setPinList([...tempPinList]);
    }
    console.log("wtf is ScanData");
    console.log(newPlace);
    await changePlaceAWS(scanData,scanData.placeName, username, company);
    tempPinList.unshift({ ...scanData });
    setPinList([...tempPinList]);
    scanner.feedbackSignal(2, 4);
    setShowChangePlace(false);
  };


  const onClickRow = (data) => {
    setScanData(data);
    setRowData(data);
    isScanShow(true);
    //TODO: What if this is send TWICE??? (clicking twice)
    //ipRenderer.send("assignNewPin", data);
  };

  const handleClickChangePlace = () => {
setShowChangePlace(true)
    closePopup();

  }

  const handleStateChange = async (state) => {
    scanData.pinStatus = state;
    scanData.lastUpdate = new Date().toISOString();
    let tempPinList = [...pinList];
    tempPinList.splice(scanData.rowIndex, 1);
    if(scanData.rowIndex===0){
      setPinList([...tempPinList]);
    }

    await changeStatePinAWS(scanData, state, company, username);
    tempPinList.unshift({ ...scanData });
    setPinList([...tempPinList]);
    scanner.feedbackSignal(2, 4);
    closePopup();
  };


  const buttonMapping = (array) => {
    return array.map((state) => {
      let item = {
        title: state.name,
        buttonClass: state.buttonClass,
      };
      if (state.action === "CHANGE_STATE") {
        item.callback = () => {
          handleStateChange(state.state);
        };
      } else if (state.action === "DELETE") {
        item.callback = handleDelete;
      } else if (state.action === "SOLD") {
        item.callback = handleSold;
      } else if (state.action === "CHANGE_PLACE") {
        item.callback = handleClickChangePlace;
      } else if (state.action === "ARRAY") {
        item.callback = async () => {
          let data = await getStatesAWS(company, state.stateArray);
          const { payload } = readDataAWS(data);
          setButtonStates(buttonMapping(payload.data));
        };
      }
      return item;
    });
  };

  const zoneMapping = (array) => {
    return array.map((state) => {
      let item = {
        title: state.name,
        buttonClass: state.buttonClass,
      };
      if (state.action === "ZONE") {
        item.callback = () => {
          handleChangePlace(state.name);
        };
      }
      return item;
    });
  };

  

  const closePopup = () => {
    isScanShow(false);
    clearInterval(scanPopupInterval);
    setScanPopupTime(modalScanTime);
    if (isFocus) {
      // freeRescanInterval = setTimeout(async () => {
      //   await freeScanning();
      // }, 2000);
    }
  };

  const timerScanPopup = () => {
    clearInterval(scanPopupInterval);
    isScanShow(true);
    setScanPopupTime(modalScanTime);
    console.log("Scan?");
    scanPopupInterval = setInterval(() => {
      setScanPopupTime((oldValue) => {
        if (oldValue < 2) {
          closePopup();
          return modalScanTime;
        }
        return oldValue - 1;
      });
    }, 1000);
  };

  const getEventsPin = async (chassis) => {
    const result = await getEventsAWS(chassis, company);
    const { payload } = readDataAWS(result);
    console.log(payload);

    setEventList(payload);
  };

  const onClickHistory = () => {
    getEventsPin(scanData.chassis);
    setShowEvents(true);
  };

  const zoneButtonArray = () => {
    let buttons = [];
    console.log("zones")
    for (let i = 0; i < zones.length; i++) {
      const element = zones[i];
      const button = (
        <span
          className={`button ${element.buttonClass}`}
          onClick={element.callback}
        >
          {element.title}
        </span>
      );
     buttons.push(button);
    }
    console.log(buttons)
    return buttons;
  };


  return (
    <>
          {scanData !== "" &&  <div class={`model ${showChangePlace ? "-active" : ""}`} data-model="status">
        <div class="model__container">
          <h2 class="model__title">Voer plaats in</h2>
          <div class={`form__control ${false ? "-error" : ""}`}>
            <label for="Plaats">Plaats</label>
            <input type="number" onChange={(e) => {setNewPlace(e.target.value)}} />
          </div>
          <span
          class="button -open-model"
          onClick={handleChangePlace}
        >Wijzig</span>
               {zoneButtonArray()}

                 <img
            class="model__cross"
            src={crossIcon}
            onClick={() => setShowChangePlace(false)}
          />
        </div>
      </div>}
      <div
        div
        className={`model -large ${showEvents ? "-active" : ""}`}
        data-model="history"
      >
        <div className="model__container">
          <h1>Historie {scanData.chassis}</h1>
          <EventTable eventList={eventList} pin={scanData.chassis} />
          <img
            src={crossIcon}
            alt=""
            className="model__cross"
            onClick={handleEventsClose}
          />
        </div>
      </div>
      <ScanPopup
        title={modalTitle}
        body={modalBody}
        popupTime={scanPopupTime}
        pinStatus = {scanData.pinStatus}
        pinID={scanData.pinID}
        show={scanShow}
        handleClose={onCloseScanPopup}
        buttons={buttonStates}
        zones = {zones}
        isReading={isReadingData}
        QRArray={QRArray}
        hasQRCode={    hasQRCode
        }
        invalidPin={invalidPin}
        printer={printer}


      />
      <section>
        {attributesAdded &&         <NewTable
          onClickRow={onClickRow}
          pinList={pinList}
          showScanTime={true}
          tableColumns={columns}
        />}

      </section>
    </>
  );
}
