import React from "react";
import historyLogo from "./../../img/history.svg";
import { useAppContext } from "../../libs/contextLib";

import crossIcon from "./../../img/icon-cross.svg";

const flattenObject = (obj) => {
  const flattened = {}

  Object.keys(obj).forEach((key) => {
    if (typeof obj[key] === 'object' && obj[key] !== null) {
      Object.assign(flattened, flattenObject(obj[key]))
    } else {
      flattened[key] = obj[key]
    }
  })

  return flattened
}
export default function ParkingCard(props) {
  const { UUID, attributes,language,placeHistory } = useAppContext();

  const generateCars = (props) => {
    let cars = [<></>];
    console.log("genCar 1")
    for (let i = 0; i < props.clickedCar.length; i++) {
      const element = props.clickedCar[i];
      console.log("genCar 2")
      console.log(element)
      let pin = flattenObject(element)
      let body = [];
      body.push(
      <tr>
        <td>{UUID} </td>
        <td>{pin.chassis}</td>
      </tr>)
        body.push(attributes.flatMap( (element) => {
          if(element.default){
            return (
              <tr>
              <td>{element[`string${language}`]}</td>
              <td>{pin[element.attribute]}</td>
            </tr>)
          }
          else{
            return [];
          }

        }))
        body.push(
          <tr>
          <td>Status </td>
          <td>{pin.pinStatus}</td>
        </tr>
        )
        cars.push(  <> <h1>{pin.chassis}</h1>      <table class="table -minimal -small">
        {body}      </table>
</>    )
    }
    return cars;

  }


  return (
    <>
      <h1>Parkeerplaats {props.pos}</h1>
      <h2>Bezetting {props.clickedCar.length}</h2>

          <>
          { generateCars(props)}
          </>
          <img
            src={crossIcon}
            alt=""
            class="model__cross"
            onClick={props.handleClose}
          />
    </>
  );
}
