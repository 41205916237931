import React, { useState, useEffect } from "react";
//import "./App.css";
import NewTable from "./NewTable";
import AddAssetForm from "./AddAssetForm";
import { useHistory } from "react-router-dom";
import { useAppContext } from "./../libs/contextLib";
import {customCarret} from "./../libs/carret";
import { flattenObject } from "../libs/helperFunctions";
import ScanPopup from "./../Containers/ScanPopup";
import readXlsxFile from "read-excel-file";
import {
  checkExistenceAWS,
  readDataAWS,
  getCompanyPinsAWS,
  insertPinDBAWS,
  getNewAssetsAWS,
  addManualAssetAWS
} from "./../libs/lambdaLib";

let scanPopupInterval;

let modalScanTime = 9;

let columns = [

];

let attributesAdded = false;

export const states = {
  FREESCAN: 1,
  NEWASSIGN: 2,
  OLDASSIGN: 3,
  EXISTINGPIN: 4,
  PINASSIGNED: 5,
  INVALIDPIN: 6,
  STOPPED: 7,
  UNREGISTEREDPIN: 8,
  READINGDATA: 9,
  ERROR: 10,
};

const stringToType = (str) => {
  switch (str) {
    case "Number":
      return Number
      break;
    case "Date":
      return Date
      break
    default:
      return String
      break;
  }
}

export default function Home() {
  const [scanData, setScanData] = useState("");
  const [xlsxData, setXlsxData] = useState([]);
  const [scanShow, isScanShow] = useState(false);
  const [manualAddShow, isManualAddShow] = useState(false);
  const [scanPopupTime, setScanPopupTime] = useState(modalScanTime);
  const [pinList, setPinList] = useState([]);
  const [oldPinList, setOldPinList] = useState([]);
  const [newlyAssignedPinList, setNewlyAssignedPinList] = useState([]);
  const [importedPins, setImportedPins] = useState(false);
  const [showImportButtonError,setShowImportButtonError] = useState(false);
  const [importButtonErrorText,setImportButtonErrorText] = useState("Wrong format");
  const [excelColumns,setExcelColumns] = useState({"test":{}});

  const {
    userHasAuthenticated,
    company,
    username,
    scanner,
    setReaderOpen,
    isFocus,
    readerOpen,
    attributes,
    UUID,
    language,
    printer
  } = useAppContext();
  const history = useHistory();
  const [isGettingPins, setIsGettingPins] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalBody, setModalBody] = useState("");
  const [modalClass, setModalClass] = useState("none");
  const [isReadingData, setIsReadingData] = useState(false);

  // Registering callbacks.. Only setstates allowed here, as readings states gives you back initial states

  useEffect(() => {
    getNewPins();
  }, []);

  useEffect(() => {
    if (isFocus) {
    } else {
      closePopup();
    }
  }, [isFocus]);

  useEffect(() => {
    if(attributes.length > 0){
      columns = [];
      //console.log("attributes");
      let excelCol = {};
      excelCol[UUID] = {
        prop: UUID,
        type: String,
        required:true
      };
      columns.push({
        dataField: "chassis",
        text: UUID,
        sortCaret: (order, column) => {
          return customCarret(order, column);
        },
        sort: true
          });

      for (let i = 0; i < attributes.length; i++) {
        const element = attributes[i];
        const line =   {
          dataField: element.attribute ,
          text:  element[`string${language}`],
          hidden: element.default===0?true:false,
          sortCaret: (order, column) => {
            return customCarret(order, column);
          },
          sort: true,
        }
        excelCol[element.attribute] = {
            prop: element.attribute,
            type: element.type?stringToType(element.type):String,
          };
        columns.push(line);
      }
      columns.push({
        dataField: "pinStatus",
        text: "Status",
        sort: true,
        sortCaret: (order, column) => {
          return customCarret(order, column);
        },
      })
      console.log("Excel Col");
      console.log(excelCol);
      setExcelColumns(excelCol);
      attributesAdded = true;
    }
  },
  [attributes])




  const getNewPins = async () => {

    const data =   await getNewAssetsAWS(company)
    let newCars = readDataAWS(data).payload;

    for (let i = 0; i < newCars.length; i++) {
      newCars[i] = flattenObject(newCars[i]);
    }

    console.log(newCars)
    setPinList(newCars);
    setXlsxData(newCars);

  }

  const onClickRow = async (data) => {
    console.log("Row data " + data);
    setScanData(data);
    freeScanning(data);
    setModalTitle("Wijs pin toe aan:");
    console.log("Wijs pin toe aan:");
    console.log(data);
    setModalBody(modalBodyScanned(data));
    timerScanPopup();
  };

  async function compareExcelDB(excelData) {
    let changedExcelData = [];
    let uuid;
    try {
      for (let i = 0; i < excelData.length; i++) {
        const excelUID = excelData[i][UUID];
        excelData[i].chassis = excelUID;
        //delete excelData[i][UUID]
        //HARDCODED REGISTRATION ENTRY FOR CORNETTE
        if (excelData[i].registration) {
          console.log(excelData[i].registration);
          if( excelData[i].registration.getTime() ===  excelData[i].registration.getTime()){     excelData[i].registration = excelData[i].registration.toISOString();}
          else{
            delete excelData[i].registration;
          }

     }
        }
        console.log(excelData)
      const data = await getCompanyPinsAWS(company);
      let oldCars = readDataAWS(data).payload;
      let newCars = [...excelData];
      for (let i = 0; i < oldCars.length; i++) {
        for (let j = 0; j < newCars.length; j++) {
          // If in the excel, old cars are still there
          if (
            oldCars[i].chassis === newCars[j].chassis &&
            oldCars[i].pinStatus !== "Sold" &&
            oldCars[i].pinStatus !== "Delete"
          ) {
            newCars.splice(j, 1);
            break;
          }
        }
      }

      console.log(newCars);
      return { newCars, oldCars };
    } catch (e) {
      console.log(e);
    }
  }

  const timerScanPopup = () => {
    clearInterval(scanPopupInterval);
    isScanShow(true);
    setScanPopupTime(modalScanTime);
    console.log("Scan?");
    scanPopupInterval = setInterval(() => {
      setScanPopupTime((oldValue) => {
        if (oldValue < 2) {
          closePopup();
          return modalScanTime;
        }
        return oldValue - 1;
      });
    }, 1000);
  };

  async function freeScanning(data) {
    console.log("going to freescan");
    if (!scanner) {
      return;
    }
    try {
      const result = await scanner.startScanning(modalScanTime * 5);
      if (result) {
        setModalTitle("Data ophalen");
        setModalBody("");
        setIsReadingData(true);
        timerScanPopup();
        const resultExistence = await checkExistenceAWS(
          result,
          company,
          username
        );
        const { statusCode, payload, message } = readDataAWS(resultExistence);
        const payloadExistence = payload;
        console.log(statusCode);
        if (statusCode === 404) {
          setModalTitle("Geen geldige Smartpin");
          setModalBody("");
          setModalClass("danger");
          scanner.feedbackSignal(2, 2);
        } else if (statusCode == 200) {
          setModalTitle("Pin al geregistreerd");
          setModalBody("Gebruik een andere pin");
          setModalClass("warning");
          scanner.feedbackSignal(0, 1);

          return;
        } else {
          const resultAssign = await insertPinDBAWS(
            data,
            result,
            username,
            company
          );
          console.log(resultAssign);
          const { payload } = readDataAWS(resultAssign);
          setModalTitle("Nieuwe pin toegewezen");
          setModalBody(modalBodyScanned(data));
          setModalClass("success");
          let list = pinList;
          console.log(list);
          list = list.filter(function (obj) {
            return obj.chassis !== data.chassis;
          });
          setPinList(list);
          setNewlyAssignedPinList([payload, ...newlyAssignedPinList]);
          scanner.feedbackSignal(2, 4);
        }
      }
    } catch (e) {
      if (e !== "" && e !== "stopped") {
        setReaderOpen(false);
        console.log(e);
      }
    }
  }

  const loadFile = (event) => {
    console.log(excelColumns)

    readXlsxFile(event.target.files[0], { schema:excelColumns}).then(async ({rows,errors}) => {
      console.log(rows)
        if(errors.length > 0){
          setShowImportButtonError(true);
          return;
        }
        else{       console.log(rows)
          setShowImportButtonError(false);
          const { newCars, oldCars } = await compareExcelDB(rows);
          setXlsxData( (prev) => {
            return [...newCars,...prev]});
          setPinList( (prev) => {
            return [...newCars,...prev]});
          setImportedPins(true);}


      //Zet het dan ook weer af!
    });
  };
  const modalBodyScanned = (data) => {
    console.log("scanData" + data);
    let body = [];
    body.push(
      <tr>
        <td>{UUID}</td>
        <td>{data.chassis}</td>
      </tr>)
      body.push(attributes.flatMap( (element) => {
        if(element.default){
          return (
            <tr>
            <td>{element[`string${language}`]}</td>
            <td>{data[element.attribute]}</td>
          </tr>)
        }
        else{
          return [];
        }

      }))

    return (
      <table className="_table -small">
        {body}
      </table>
    );
  };


 async function postAsset(uuid,attributes){

  try{
    await addManualAssetAWS(uuid,attributes,username,company);
    let tempPinList = [...pinList]
    tempPinList.unshift({chassis:uuid,pinStatus:"Added",...attributes});
    setPinList([...tempPinList])
    isManualAddShow(false);
  }
catch(e){

}
  }




  const closePopup = () => {
    isScanShow(false);
    clearInterval(scanPopupInterval);
    setScanPopupTime(modalScanTime);
    setModalClass("none");
  };

  return (
    <>
      <ScanPopup
        printer={printer}
        title={modalTitle}
        body={modalBody}
        popupTime={scanPopupTime}
        show={scanShow}
        handleClose={closePopup}
        buttons={[]}
        isReading={isReadingData}
      />
      <AddAssetForm
      show = {manualAddShow}
      handleClose={() => {isManualAddShow(false)}}
      postManualAsset={postAsset}/>
      <section>
        {attributesAdded &&         <NewTable
          onClickRow={onClickRow}
          importButton={true}
          pinList={pinList}
          loadFile={loadFile}
          tableColumns={columns}
          showImportButtonError={showImportButtonError}
          importButtonErrorText={importButtonErrorText}
          addButton={true}
          addManual = {() => {isManualAddShow(true)}}
        />}

        <>
          {/* <Card className={classes.Collapse} hidden={!importedPins}>
            <Button
              variant="light"
              size="lg"
              block
              onClick={() => setAssignedOpenNew((oldValue) => !oldValue)}
            >
              Net toegewezen pins{" "}
              {assignedOpenNew ? (
                <FontAwesomeIcon icon={faAngleUp} />
              ) : (
                <FontAwesomeIcon icon={faAngleDown} />
              )}
            </Button>
            <Collapse in={assignedOpenNew}>
              <Card.Body>
                <NewlyAssignedTable pinList={newlyAssignedPinList} />
              </Card.Body>
            </Collapse>
          </Card> */}
        </>
      </section>
    </>
  );
}
