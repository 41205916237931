import React, {useState,useEffect, useRef} from "react";
import classes from "./ScanPopup.module.css";
import historyIcon from "./../img/history.svg";
import crossIcon from "./../img/icon-cross.svg";
import { useAppContext } from "./../libs/contextLib";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQrcode,faPrint } from "@fortawesome/free-solid-svg-icons";
import ReactDOM from 'react-dom';
import QRCodeSVG from "qrcode.react"


class TextComponent extends React.PureComponent {

  render() {
    return (<div className={classes.printing}><p>  {this.props.content}</p></div>

    );
  }
}

export default function Popup(props) {

  const [showQR, setShowQR] = useState(false);
  const [labelQR, setLabelQR] = useState(true);
  const [QRString, setQRString] = useState("");
    const ref = useRef(null);

    const {
      userGroup
    } = useAppContext();
  useEffect(() => {

    if(props.QRArray && props.QRArray.length > 0){
      setQRString(generateQRcode(props.QRArray));
      console.log("printer");

      console.log(props.printer.type);
      setLabelQR(true)
      if(props.printer.type && props.printer.type.length > 0){
        if(props.printer.type.length > 1){
          setLabelQR(true);
      }
      else if(props.printer.type.includes("label")){
        setLabelQR(true);

      }
      else{
        setLabelQR(false);

      }
    }
    }
  },[props.QRArray])

  useEffect(() => {

    if(props.QRArray && props.QRArray.length > 0){

      setQRString(generateQRcode(props.QRArray));

    }
  },[labelQR]);

  const generatePrintComponent = (array) => {
    let result = ["START",<br/>]
    for (let i = 0; i < array.length; i++) {
      result.push([array[i],<br/>])
    }
    if(labelQR)   result.push("label",<br/>)
    else    result.push("pols",<br/>)
    if(props.pinID){
      result.push(`https://mobile.codek.be/#/${props.pinID}`,<br/>)
    }
    else{
      result.push("https://www.codek.pro/",<br/>)
     }
     result.push("END")
      return result
}

const onDirectPrintClick = () => {
  var text = "the code to run";
    var bad = "Hey";
    var x = new XMLHttpRequest();
    x.open("POST", "http://localhost:3069/data");
    x.setRequestHeader("Content-Type", "application/json");
    x.send(JSON.stringify({QRString}))
//   var req = http.request(HTTPoptions, () => {
//     console.log("Done direct print")
//   });
// //This is the data we are posting, it needs to be a string or a buffer
// req.write("data");
// req.end();
}

const generateQRcode = (array) => {
  let text = "START"
  if(!labelQR && props.printer.autodialog)  text=`${text}\nCodek`

  for (let i = 0; i < array.length; i++) {
    text=`${text}\n${array[i]}`
}
if(labelQR)  text=`${text}\nlabel`
else   text=`${text}\npols`
if(props.pinID){
   text=`${text}\nhttps://mobile.codek.be/#/${props.pinID}`}
else{
    text=`${text}\nhttps://www.codek.pro/`
 }
 text=`${text}\nEND`;
 //text=`${text}\n${array[i]}`;
return text;
}


  const buttonArray = () => {
    let buttons = [<></>];
    for (let i = 0; i < props.buttons.length; i++) {
      const element = props.buttons[i];
      const button = (
        <span
          className={`button ${element.buttonClass}`}
          onClick={element.callback}
        >
          {element.title}
        </span>
      );
      if( !(props.pinStatus === "In Board" && element.action === "CHANGE_PLACE" && element.disabledInBoard)) buttons.push(button);
    }
    return buttons;
  };

  const onClickQR = () => {
    console.log(QRString);
    if(props.printer.type && props.printer.type.length > 1){
      setLabelQR(true);

    }
    setShowQR(!showQR);

  }

  const handleClose = () => {
    setShowQR(false);
    props.handleClose();
  }

  return (
    <>
      <div className={`model ${props.show ? "-active" : ""}`} data-model="pin">
        <div class="model__container">
          <h1>{props.title}</h1>
          {props.body}
           <div className="buttons"> {props.history && (
            <>
            <span
              class="button -transparent -open-model"
              data-model="history"
              onClick={props.onClickHistory}
            >
              <img src={historyIcon} alt="" />
              Historiek bekijken
            </span>
            {/* <ul className="progressbar">
          <li className="active step0"></li>
            <li className="active step0"></li>
            <li className="active step0"></li>
            <li className="step0"></li>
        </ul> */}
        </>
          )}
               <> {props.hasQRCode && !props.invalidPin  && <span
              class="button -transparent -open-model"
              data-model="history"
              onClick={onClickQR}
            >
                <FontAwesomeIcon icon={faQrcode} />
               QR-print
            </span>}
{/*             <ReactToPrint
content={() => ref.current}
          documentTitle="AwesomeFileName"
          removeAfterPrint
          trigger={() => {return <span
            class="button -transparent -open-model"
            data-model="history"
          >
              <FontAwesomeIcon icon={faPrint} />
             Print
          </span>}}
        /> */}

        { props.hasQRCode &&  props.printer.directPrint && <span
        class="button -transparent -open-model"
        data-model="history"
        onClick={onDirectPrintClick}
      >
          <FontAwesomeIcon icon={faPrint} />
         Direct print
      </span>}</>
            </div>

            {showQR &&<>


            <div className="QR_div">
               <QRCodeSVG value={QRString} />
              </div>
              { props.printer.type && props.printer.type.length > 1 && <div class="form__control toggle -close">
            <input type="checkbox" id={1} defaultChecked={ labelQR===0?false:true} onChange={(e) => {setLabelQR(!labelQR)}}/>
            <label for={1}>
                {labelQR?"label":"band"}
                <div class="toggle__switch">
                    <span class="toggle__check"></span>
                </div>
            </label>

        </div>}
</>
 }

          <div class="model__divider"></div>
          {props.buttons.length> 0 && <h2>{`Pin status: ${props.pinStatus}`}</h2>}
          {userGroup !== "viewers" && <div class="buttons">{buttonArray()}</div>}
          <img
            src={crossIcon}
            alt=""
            class="model__cross"
            onClick={handleClose}
          />
        </div>
        { props.hasQRCode && <TextComponent ref={ref} content={generatePrintComponent(props.QRArray)}/>}

        </div>
    </>
  );
}
