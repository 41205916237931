import React, { useState, useEffect } from "react";
import { useAppContext } from "./../libs/contextLib";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import { Form, Button, Modal } from "react-bootstrap";
import {
  readDataAWS,
  listBadgesAWS,
  updateBadgesAWS,
} from "./../libs/lambdaLib";
import paginationFactory from "react-bootstrap-table2-paginator";
import cellEditFactory, { Type } from "react-bootstrap-table2-editor";
import arrowLeft from "./../img/arrow-left.svg";
import arrowLeftDouble from "./../img/arrow-left-double.svg";
import arrowRight from "./../img/arrow-right.svg";
import arrowRightDouble from "./../img/arrow-right-double.svg";
const headerSortingStyle = { backgroundColor: "#c8e6c9" };

const customCarret = (order, column) => {
  if (!order)
    return (
      <>
        <span className="-carret" />
        <span className="-carret -open" />
      </>
    );
  else if (order === "asc") return <span className="-carret" />;
  else if (order === "desc") return <span className="-carret -open" />;
  return null;
};

let columns = [
  {
    dataField: "sk",
    text: "Badge",
    sort: true,
    sortCaret: (order, column) => {
      return customCarret(order, column);
    },
    editable: false,
  },
  {
    dataField: "status",
    text: "Status",
    sort: true,
    sortCaret: (order, column) => {
      return customCarret(order, column);
    },
    editable: false,
  },
  {
    dataField: "lastUpdate",
    text: "Laatste update",
    sort: true,
    sortCaret: (order, column) => {
      return customCarret(order, column);
    },
    editable: false,
  },
  {
    dataField: "placeName",
    text: "Plaats",
    sort: true,
    sortCaret: (order, column) => {
      return customCarret(order, column);
    },
    editable: false,
  },
  {
    dataField: "username",
    text: "Naam",
  },
];

const MySearch = (props) => {
  const handleChange = (input) => {
    console.log(input.target.value);
    props.onSearch(input.target.value);
  };
  return (
    <input
      type="text"
      className="search"
      placeholder="Zoeken"
      onChange={handleChange}
    />
  );
};

const rowStyle2 = (row, rowIndex) => {
  const style = {};
  if (row.pinStatus === "Sold" || row.pinStatus === "Delete") {
    style.backgroundColor = "#d9534f";
  }

  return style;
};

export default function BadgeTable() {
  const [badgeList, setBadgeList] = useState([]);
  const { company, username } = useAppContext();
  const [dropdownClass, setDrowndownClass] = useState("dropdown");
  const [currentPage, setCurrentPage] = useState(1);

  const onClickPageSelectorDropdown = () => {
    if (dropdownClass === "dropdown") setDrowndownClass("dropdown -open");
    else setDrowndownClass("dropdown");
  };

  const onClickPageSelectorSize = (e, onSizePerPageChange) => {
    console.log(e.target.value);
    setDrowndownClass("dropdown");
    onSizePerPageChange(e.target.value);
  };
  const sizePerPageRenderer = ({
    options,
    currSizePerPage,
    onSizePerPageChange,
  }) => (
    <div
      className={dropdownClass}
      role="group"
      onClick={onClickPageSelectorDropdown}
      onChange={(e) => onClickPageSelectorSize(e, onSizePerPageChange)}
    >
      <div className="dropdown__list">
        {options.map((option) => {
          const isSelect = currSizePerPage === `${option.page}`;
          return (
            <div className="dropdown__option">
              <input
                name="max"
                type="radio"
                value={option.text}
                id={option.text}
                checked={isSelect}
              />
              <label for={option.text}>{option.text}</label>
            </div>
          );
        })}
      </div>
    </div>
  );

  const pageListRenderer = ({ pages, onPageChange }) => {
    const pageWithoutIndication = pages.filter(
      (p) => typeof p.page !== "string"
    );

    return (
      <div className="pagination">
        <span className="pagination__item">
          <img src={arrowLeftDouble} alt="" onClick={() => onPageChange(1)} />
        </span>
        <span
          className="pagination__item"
          onClick={() => onPageChange(currentPage === 1 ? 1 : currentPage - 1)}
        >
          <img src={arrowLeft} alt="" />
        </span>
        {pageWithoutIndication.map((p) => (
          <span
            className="pagination__item"
            onClick={() => onPageChange(p.page)}
          >
            {p.page}
          </span>
        ))}
        <span
          className="pagination__item"
          onClick={() =>
            onPageChange(
              currentPage === pages.length ? pages.length : currentPage + 1
            )
          }
        >
          <img src={arrowRight} alt="" />
        </span>
        <span className="pagination__item">
          <img
            src={arrowRightDouble}
            alt=""
            onClick={() => onPageChange(pages.length)}
          />
        </span>
      </div>
    );
  };

  useEffect(() => {
    getBadges();
  }, []);

  const getBadges = async () => {
    const result = await listBadgesAWS(company);
    setBadgeList(readDataAWS(result).payload);
  };

  const cellEdit = async (oldValue, newValue, row, column) => {
    await updateBadgesAWS(row.sk.substring(6), company, newValue);
  };

  return (
    <>
      <section>
        <ToolkitProvider
          keyField="sk"
          data={badgeList}
          columns={columns}
          search
        >
          {(tableProps) => (
            <>
              <MySearch {...tableProps.searchProps} />
              <BootstrapTable
                bootstrap4={true}
                keyField="sk"
                classes="table"
                hover
                rowStyle={rowStyle2}
                {...tableProps.baseProps}
                pagination={paginationFactory({
                  sizePerPageRenderer,
                  pageListRenderer,
                  onPageChange: (page, sizePerPage) => {
                    setCurrentPage(page);
                  },
                })}
                cellEdit={cellEditFactory({
                  mode: "click",
                  blurToSave: true,
                  afterSaveCell: cellEdit,
                })}
              />
            </>
          )}
        </ToolkitProvider>
      </section>
    </>
  );
}
