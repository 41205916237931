import React, { memo } from "react";

function showButtons(props) {
  let buttons = [];
  console.log("carList")
  console.log(props.carList)
  let rowLength = props.amount < (props.row+1)*props.col? props.amount - (props.row)*props.col:props.col;
  for (let index = 0; index < rowLength; index++) {
    let elIndex = props.row * props.col + index*1 + 1;

    if(props.formatter && props.formatter.length > 0 && props.formatter[0].action === "prefix"){

      elIndex = `${props.formatter[0].substring}${"0".repeat(3-elIndex.toString().length)}${elIndex}`
      console.log(elIndex)
    }
    let buttonColor = "";
   // console.log("formatter")
    //console.log(props.formatter)
    if(Object.keys(props.carList).length > 0){
    if(props.carList[ elIndex ]&&  props.carList[ elIndex ].length === 1) buttonColor= "-green";
    else if(props.carList[ elIndex ]&&  props.carList[ elIndex ].length > 1) buttonColor= "-error";
    // else if(props.buttons[index].pinID === "u"){
    //    buttonColor= "-error";
    // };
    }
    buttons.push(
      <div className={`parking__pin  ${buttonColor}`}>
          <span className={`board__number`}  onClick={ props.carList[ elIndex ]?() => {props.handleClickPlace(props.carList[ elIndex], elIndex)}:() => {}}>{props.row * props.col + index*1 + 1} </span>
      </div>
    );
  }
  return (   <div className="parking__row">{buttons}</div>);
}

function ParkingRow(props) {
  return <>{showButtons(props)}</>;
}

export default  ParkingRow = memo( ParkingRow);
